export const PAGE_VIEW_EVENT = 'PageView';
const appId = process.env.REACT_APP_AUTH_APP_ID;

export const createPageViewEvent = (page, user) => {
    return ({
        eventDateTime: new Date().toISOString(),
        eventDetails: {
            appId,
            "endpoint": page
        },
        eventType: PAGE_VIEW_EVENT,
        user: user
    });
}