import { useRef } from 'react'

export const deepCompareEquals = (current, previous) => {
    return JSON.stringify(previous) === JSON.stringify(current);
}

export function objectsEqual(o1, o2) {
    if (o1 === null || o2 === null) {
        return false;
    }
    
    return (
        typeof o1 === 'object' && 
        typeof o2 === 'object' && 
        Object.keys(o1).length > 0 ? Object.keys(o1).length === Object.keys(o2).length && 
        Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])) : o1 === o2
    )
}

export const useDeepCompareWithRef = (value, removeKey) => {
    const ref = useRef();
    const result = Object.assign({}, value);
    delete result[removeKey];
    if (!deepCompareEquals(result, ref.current)) {
        //ref.current contains the previous object value
        ref.current = result;
    }
    return ref.current;
}