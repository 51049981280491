import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    thead {
        text-align: left;
        tr {
            color: #7B7B7B;
        }
        th {
            padding: .4rem;
        }
    }
    tbody {
        background-color: $lightGray;
        font-weight: bolder;
        color: #606060;
        tr {
            border-bottom: white solid 2px;
        }
        
        td {
            padding: .5rem;
        }
    }
`;

function TableComponent({ columns, data, tbodyClassName, cellProps }) {
    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <Table {...getTableProps()}>
            <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()}>
                                        {// Render the header
                                            column.render('Header')}
                                    </th>
                                )
                                )}
                        </tr>
                    ))}
            </thead>

            {/* Apply the table body props */}
            <tbody className={tbodyClassName} {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {// Render the cell contents
                                                    cell.render('Cell', cellProps)}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
            </tbody>
        </Table>
    );
}

export default TableComponent;
