import React from 'react';
import { VscClose } from "react-icons/vsc";
import ReactModal from 'react-modal';
import styled from 'styled-components';

const OverlayStyleDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 900;
`;

const ModalDiv = styled.div`
  position: fixed;
  height: 85vh;
  width: 85vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  z-index: 900;
  padding: 10px 20px;
`;

const ModelContentDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1rem;
  margin-right: 1.1rem;
  overflow-y: auto;
`;

const CloseButton = styled.a`
  position: absolute;
  top: .25rem;
  right: .25rem;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

function ContentElement(props, children) {
  return (
    <ModalDiv {...props}>
      {children}
    </ModalDiv>
  );
}

function OverlayElement(props, contentElement) {
  return (
    <OverlayStyleDiv {...props}>
      {contentElement}
    </OverlayStyleDiv>
  );
}

function Modal({ open, children, onClose }) {

  return (
    // Note that the className properties are required to prevent react-modal
    // from overriding the styles defined in contentElement and overlayElement
    <ReactModal
      className="_"
      overlayClassName="_"
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      contentElement={ContentElement}
      overlayElement={OverlayElement}>
      <ModelContentDiv>
        {children}
      </ModelContentDiv>
      <CloseButton onClick={onClose}>
        <VscClose size={'2rem'} />
      </CloseButton>
    </ReactModal>
  );
}

export default Modal;
