import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { Report, useReport } from './Report';
import Modal from './Modal';
import { init, reducer, SET_MAIN_GEOGRAPHY } from '../reducers/geography.reducer';
import AppContext from '../AppContext';
import PayerInfo from './PayerInfo';
import { geoFilters } from '../util/geoFilters';
import ViewMoreButton from './ViewMoreButton';

function PayerLandscapeReportContainer({ payerLandscapeReportId, topSubReportId, topSubReportTitle }) {
	const { configuration } = useContext(AppContext);
	const [state, dispatch] = useReducer(reducer, configuration, init);

	const geographySelection = state.geographySelection;
	const setMainGeography = useCallback(
		(value) =>
			dispatch({
				type: SET_MAIN_GEOGRAPHY,
				value: value,
			}),
		[dispatch]
	);

	const { mainGeography, history } = geographySelection;
	const { geoLevelOptions, skipNationalAsDefaultGeography } = configuration || {};

	// Set the special geography up/down filtering
	const geoLevelFilters = useMemo(() => geoFilters(history, mainGeography, false), [history, mainGeography]);
	const payerLandscapeReport = useReport({ reportId: payerLandscapeReportId, filters: geoLevelFilters });

	const [payerInfo, setPayerInfo] = useState(null);

	const onCloseModal = useCallback(() => setPayerInfo(null), [setPayerInfo]);

	const components = useMemo(
		() => ({
			info: ({ row }) => <ViewMoreButton onClick={() => setPayerInfo(row)} />,
		}),
		[setPayerInfo]
	);

	if (!configuration) {
		return null;
	}

	return (
		<>
			{payerInfo && (
				<Modal open={true} onClose={onCloseModal}>
					<PayerInfo parentReport={payerLandscapeReport} mainGeography={mainGeography} payerInfo={payerInfo} reportId={topSubReportId} title={topSubReportTitle} />
				</Modal>
			)}
			<Report
				report={payerLandscapeReport}
				mainGeography={mainGeography}
				setMainGeography={setMainGeography}
				geoLevelOptions={geoLevelOptions}
				components={components}
				skipNationalAsDefaultGeography={skipNationalAsDefaultGeography}
			/>
		</>
	);
}

export default PayerLandscapeReportContainer;
