const appId = process.env.REACT_APP_AUTH_APP_ID;
const authorityDomain = process.env.REACT_APP_AUTH_AUTHORITY_DOMAIN;
const tenant = process.env.REACT_APP_AUTH_TENANT;

// For SSO users
const signInUserFlow = process.env.REACT_APP_AUTH_SIGNIN_FLOW;
// For non-sso users
const loginUserFlow = process.env.REACT_APP_AUTH_LOGIN_FLOW;

const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT ||  (window.location.protocol + "//" + window.location.host);
const apiScope = process.env.REACT_APP_AUTH_API_SCOPE;
const logoutUrl = process.env.REACT_APP_AUTH_LOGOUT_URL;


export const ssoAuthority = `https://${authorityDomain}/${tenant}/${signInUserFlow}`;
export const nonSsoAuthority = `https://${authorityDomain}/${tenant}/${loginUserFlow}`;

export const msalConfig = {
    auth: {
        clientId: appId,
        redirectUri: redirectUrl,
        knownAuthorities: [authorityDomain],
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
    scopes: [
        "openid",
        "profile",
        "offline_access",
        apiScope
    ]
};

export const logoutRequest = {
    postLogoutRedirectUri: logoutUrl
};
