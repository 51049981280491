import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import Modal from './Modal';

import { Report, useReport } from './Report';
import AppContext from '../AppContext';
import PrescriberInfo from './PrescriberInfo';
import { queryReport } from '../api/report';
import { init, reducer, SET_MAIN_GEOGRAPHY } from '../reducers/geography.reducer';
import { geoFilters } from '../util/geoFilters';
import ViewMoreButton from './ViewMoreButton';
import { reports } from '../reports';

function RepMessagesReportContainer({ reportId, hcpInfoReportId, payerMixReportId, entityAlias, hcpType }) {
	const { startOp, configuration } = useContext(AppContext);

	const [state, dispatch] = useReducer(reducer, configuration, init);
	const [hcpMessagesReportId, setHcpMessagesReportId] = useState();
	const [top5PlansReportId, setTop5PlansReportId] = useState();
	const [podGeneration, setPodGeneration] = useState();
	const geographySelection = state.geographySelection;

	const setMainGeography = useCallback(
		(value) =>
			dispatch({
				type: SET_MAIN_GEOGRAPHY,
				value: value,
			}),
		[dispatch]
	);

	const { mainGeography, history } = geographySelection;
	const { geoLevelOptions, skipNationalAsDefaultGeography } = configuration || {};
	
	// Set the special geography up/down filtering
	const geoLevelFilters = useMemo(() => geoFilters(history, mainGeography, false), [history, mainGeography]);

	const hcpsReport = useReport({ reportId: reportId, filters: geoLevelFilters });

	const [selectedHcpRow, setSelectedHcpRow] = useState(null);
	const [prescriberInfo, setPrescriberInfo] = useState(null);

	useEffect(() => {
		if (!selectedHcpRow) {
			setPrescriberInfo(null);
			return;
		}

		startOp(async () => {
			const { results } = await queryReport(hcpInfoReportId, {
				filters: {
					...hcpsReport.filters,
					// TODO Document Hardcoded Property 'DisplayPrescriberID'
					DisplayPrescriberID: selectedHcpRow.DISPLAYPRESCRIBERID,
				},
			});

			results.sort((a, b) => {
				const orderA = a.Order ? a.Order : 0;
				const orderB = b.Order ? b.Order : 0;
				if (orderA > orderB) { return 1; }
				if (orderA < orderB) { return -1; }
				return 0;
			});

			setPrescriberInfo({
				//TODO Hardcoded DisplayPrescriberID from 'hcps' report
				prescriberId: selectedHcpRow.DISPLAYPRESCRIBERID,
				//TODO Hardcoded PrescriberName from 'hcps' report
				hcp: selectedHcpRow.PRESCRIBERNAME,
				//TODO Hardcoded AccessPercent from 'hcps' report
				access: selectedHcpRow.ACCESSPERCENT,
				// TODO Document Hardcoded Report Property names from 'hcp_stats'
				messageStats: results.map(({ Label, Value, Color }) => ({ label: Label, value: Value, color: Color })),
				messageCount: selectedHcpRow.MESSAGECOUNT ? selectedHcpRow.MESSAGECOUNT : null,
			});
		});
	}, [selectedHcpRow, hcpsReport.filters, startOp, hcpInfoReportId]);

	useEffect(() => {
		if (!hcpInfoReportId || !hcpsReport.filters || !hcpsReport.filters.ProductID) {
			return;
		}

		const apiConfig = configuration.podApis.find((api) => api.productIds.includes(hcpsReport.filters.ProductID));
		const isHcp = hcpInfoReportId === reports.hcpInfo.id;

		if (apiConfig?.generation === 2) {
			setPodGeneration(2);
			if (isHcp) {
				setHcpMessagesReportId(reports.hcpMessagesPod2.id);
				setTop5PlansReportId(reports.hcpTop5PlansPod2.id);
			} else {
				setHcpMessagesReportId(reports.groupPracticeMessagesPod2.id);
				setTop5PlansReportId(reports.groupPracticeTop5PlansPod2.id);
			}
		} else {
			setPodGeneration(1);
			if (isHcp) {
				setHcpMessagesReportId(reports.hcpMessages.id);
				setTop5PlansReportId(reports.hcpTop5Plans.id);
			} else {
				setHcpMessagesReportId(reports.groupPracticeMessages.id);
				setTop5PlansReportId(reports.groupPracticeTop5Plans.id);
			}
		}
	}, [hcpsReport.filters, hcpInfoReportId, configuration.podApis]);

	const onCloseModal = useCallback(() => setSelectedHcpRow(null), []);
	const components = useMemo(
		() => ({
			info: ({ row }) => <ViewMoreButton onClick={() => setSelectedHcpRow(row)} />,
		}),
		[setSelectedHcpRow]
	);

	if (!configuration) {
		return null;
	}

	return (
		<>
			{prescriberInfo && (
				<Modal open={true} onClose={onCloseModal}>
					<PrescriberInfo
						parentReport={hcpsReport}
						prescriberInfo={prescriberInfo}
						payerMixReportId={payerMixReportId}
						hcpMessagesReportId={hcpMessagesReportId}
						top5PlansReportId={top5PlansReportId}
						entityAlias={entityAlias}
						hcpType={hcpType}
						podGeneration={podGeneration}						
					/>
				</Modal>
			)}
			<Report
				report={hcpsReport}
				mainGeography={mainGeography}
				setMainGeography={setMainGeography}
				geoLevelOptions={geoLevelOptions}
				components={components}
				skipNationalAsDefaultGeography={skipNationalAsDefaultGeography}
			/>
		</>
	);
}

export default RepMessagesReportContainer;
