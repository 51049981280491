// Calculates bounding box from an array of features
export default function calculateFeaturesBoundingBox(features) {
    const bounds = {};

    for (const feature of features) {
        const geometry = feature.geometry;
        if (!geometry)
            continue;;

        if (geometry.type === 'Polygon') {
            for (const polygon of geometry.coordinates) {
                for (const point of polygon) {
                    const longitude = point[0];
                    const latitude = point[1];
                    bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
                    bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
                    bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
                    bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
                }
            }
        }
        else if (geometry.type === 'MultiPolygon') {
            for (const multiPolygon of geometry.coordinates) {
                for (const polygon of multiPolygon) {
                    for (const point of polygon) {

                        const longitude = point[0];
                        const latitude = point[1];
                        bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
                        bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
                        bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
                        bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
                    }
                }
            }
        }
    }

    return [
        [bounds.xMin, bounds.yMin],
        [bounds.xMax, bounds.yMax]
    ];
}
