import { apiGet, apiPost } from './AGIAPI';

export async function getReportConfiguration(reportId) {
    const reportConfiguration = await apiGet(`report/${encodeURIComponent(reportId)}`);
    const { rowId, reportFilters, columnConfiguration, geoLevelOptions } = reportConfiguration;

    return {
        rowId,
        geoLevelOptions,
        filters: reportFilters,
        columns: columnConfiguration
    };
}

export async function queryReport(reportId, options) {
    const filters = Object.fromEntries(Object.entries(options.filters).filter(([k, v]) => v !== null && (!Array.isArray(v) || v.length > 0)));
    const pageSize = options.pageSize ?? 100;

    const settings = {
        filters: filters,
        continuationToken: options.continuationToken,
        pageSize,
        columns: options.columns,
        order: options.order
    };

    const queryResult = await apiPost(`report/${encodeURIComponent(reportId)}`, settings);

    return {
        results: queryResult.results,
        continuationToken: queryResult.continuationToken,
        totalRecords: queryResult.totalRecords,
    };
}

export async function getDynamicOptions(request, colId) {
    const filters = Object.fromEntries(Object.entries(request).filter(([k, v]) => v !== null && (!Array.isArray(v) || v.length > 0)));
    const settings = {
        filters: filters,
        search: "", // for potential future use
    };

    const response = await apiPost(`filter/${colId}`, settings);
    const { filterOptions } = response;
    return filterOptions;
}

