import React, { useState, useCallback, useMemo, useEffect, useContext } from "react";
import Select from "react-select";
import { SelectedFiltersContext } from "../util/SelectedFiltersContext";
import styled, { useTheme } from "styled-components";
import WindowedSelect from "react-windowed-select";
import { objectsEqual } from "../util/useDeepCompareWithRef";

const Menu = styled.div`
    background-color: white;
    border-radius: 4;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
    margin-top: 8;
    min-width: 25rem;
    position: absolute;
    z-index: 900;
`;

const Blanket = styled.div`
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
`;

const ChevronDown = () => (
    <svg width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        color='#d0d0d0'
        role="presentation">
        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
);

const CONTROL_STYLE = {
    minWidth: '22rem',
    border: '3px solid #E6E6E6',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
        border: '3px solid #E6E6E6',
    },
    boxShadow: 'none',
    minHeight: '3.25rem',
    padding: '4px'
}

const InnerDropDown = styled.div`
    border: 3px solid #E6E6E6;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2px 14px;
    display: flex;
    min-width: 22rem;
    justify-content: space-between;
    align-items: center;
    min-height: 3.25rem;
    background-color: white;
    color: black;
    cursor: pointer;
`;

function isIncludingString(string, option) {
    let result = false;
    if (!string || option.label.toString().toLowerCase().includes(string) || option.value.toString().toLowerCase().includes(string)) {
        result = true;
    }
    return result;
}

const filterOption = ({ label, value }, string) => {
    if (!string) {
        return true;
    }

    if (value === "all") {
        return true;
    }

    if (string) {
        return label.includes(string.toLowerCase()) || value.toString().toLowerCase().includes(string.toLowerCase());
    }
};

const MultiValue = ({ index, getValue }) => !index && `${getValue().length} items selected`;

const maxLength = 3;
const selectAllOption = {
    label: 'Select All',
    value: 'all'
};

export default function DropdownFilter({ filterKind, label, options, onChange, value, isMulti, components, isHcpName = false, colId = null, setOptions, filterState, currentStateValue, defaultOption, ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    const [_selectedValue, setSelectedValue] = useState(value); // what is selected
    const [userInput, setUserInput] = useState(''); // user input
    const theme = useTheme();

    const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
    
    const selectStyles = useMemo(() => ({
        control: (provided) => ({ ...provided, ...CONTROL_STYLE }),
        option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            backgroundColor: isSelected ? theme.colors.selected : 'white',
            ':hover': {
                backgroundColor: theme.colors.highlight
            },
            color: 'black'
        })
    }), [theme]);

    const isSelectedFiltersEmpty = useCallback( () => {
        return Object.keys(selectedFilters).length === 0 && selectedFilters.constructor === Object;
    }, [selectedFilters]);

    // persist non-multi-select filters across page changes, update results
    useEffect(() => {
        if (filterKind === 'show-hide-columns') {
            return;
        }

        const filterLabel = label?.trim().toLowerCase();

        // non-multi-select filters
        if (selectedFilters[filterLabel] && !objectsEqual(selectedFilters[filterLabel], value)) { // check if current filter is in selectedFilters AND not selected yet
            if (!options || options.length === 0) {
                return;
            } else {

                // if true, find matching value and set it 
                options.forEach(option => {
                    if (option.value === selectedFilters[filterLabel] && option.value !== _selectedValue.value) {
                        onChange(option)
                    }
                })
            }
        }


        //if the current state value (value set in state at higher level), is not in the option list, 
        //then set the value to the default option if defined, or to the first option in the list and trigger the onchange so the state is updated
        if(options && options.length > 0 && currentStateValue && !options.some(option => option.value === currentStateValue )){            
            setSelectedValue(defaultOption ? defaultOption : options[0]);
            onChange(defaultOption ? defaultOption : options[0]);            
        }

    }, [label, options, _selectedValue.value, selectedFilters, currentStateValue, filterKind, value, onChange, defaultOption]);

    // persist multi-select filters across page changes, update results
    useEffect(() => {
        if (filterKind === 'show-hide-columns') {
            return;
        }

        const filterLabel = label?.trim().toLowerCase();

        // multi-select filters that don't have options ('text-suggest')
        if (options.length === 0 && selectedFilters[filterLabel] && filterKind === 'text-suggest') {
            if (selectedFilters[filterLabel] && Array.isArray(selectedFilters[filterLabel]) && selectedFilters[filterLabel].length > 0 && !objectsEqual(selectedFilters[filterLabel], value || [])) {
                onChange(selectedFilters[filterLabel]);
            }
            return;
        }

        // multi-select filters that have options
        if (options.length > 0 && selectedFilters[filterLabel] && Array.isArray(selectedFilters[filterLabel]) && selectedFilters[filterLabel].length > 0 && !objectsEqual(selectedFilters[filterLabel], value)) {
            const matchingOptions = [];

            // find matching options
            selectedFilters[filterLabel].forEach(filter => {
                options.forEach(option => {
                    if (option.value === filter.value) {
                        matchingOptions.push(option);
                    }
                })
            })

            setSelectedFilters({ ...selectedFilters, [filterLabel]: matchingOptions});
            onChange(matchingOptions);
        }
    }, [options, filterKind, label, selectedFilters, value, onChange, setSelectedFilters])

    // TODO this really shouldn't be here but it's the quickest I have atm to line up the
    // incoming value with the internal one
    useEffect(() => {

        // bug fix for 'reset filters' not working on Payer TOP HCPs for 'Select Payers' filter
        if (isSelectedFiltersEmpty() && value && value.length > 0) {
            setSelectedValue([])
            onChange([])
            return;
        }

        setSelectedValue(value);        
    }, [value, onChange, isSelectedFiltersEmpty]);

    useEffect(() => {
        if (isSelectedFiltersEmpty()) {
            if (filterKind === 'show-hide-columns' || filterKind === 'multiselect' || filterKind === 'multi-suggest' || filterKind === 'text-suggest') {
                setSelectedValue([]);
                onChange([])
                return;
            }

            if(defaultOption){
                setSelectedValue(defaultOption);
                onChange(defaultOption);
            }
            else if (options && Array.isArray(options) && options[0]) {
                setSelectedValue(options[0]);
                onChange(options[0])
            }
        }
    }, [selectedFilters, filterKind, isSelectedFiltersEmpty]);


    

    const multiCleanOptions = isMulti && _selectedValue.filter(x => x !== undefined);
    const isMultiOptions = multiCleanOptions && multiCleanOptions.length

    const selectOptions = useMemo(() => {

        const enhancedOptions = isMulti && userInput && !isHcpName ? [selectAllOption, ...options] : options;

        if (isMultiOptions) {
            const valueFilter = multiCleanOptions.map(item => item.value)
            const filteredOptions = enhancedOptions.filter(item => !valueFilter.includes(item.value));

            return multiCleanOptions.concat(filteredOptions);
        }

        return enhancedOptions;
    }, [multiCleanOptions, isMultiOptions, options, isMulti, userInput, isHcpName]);

    const enhancedComponents = useMemo(() => {
        return multiCleanOptions && multiCleanOptions.length > maxLength ? { ...components, MultiValue } : { ...components }
    }, [components, multiCleanOptions])

    options = selectOptions

    if (isHcpName && !userInput) {
        options = [];
    }

    const toggleOpen = useCallback(() => setIsOpen(prev => !prev), [setIsOpen]);

    const onSelectChange = (value, { option }) => {
        const filterLabel = label?.trim().toLowerCase();
        const optionValue = value.value || value; // could be object or array

        if (option?.value === 'all') {

            const filteredOptions = options.filter(filteredOption => {
                return isIncludingString(userInput.toLowerCase(), filteredOption) && !value.includes(filteredOption)
            })

            const containerValue = value.concat(filteredOptions)
            const selectAllIdx = containerValue.indexOf(selectAllOption)

            if (selectAllIdx > -1) {
                containerValue.splice(selectAllIdx, 1)
            }

            setSelectedFilters({
                ...selectedFilters,
                [filterLabel]: containerValue, // use label because id is page specific, label is universal
            });

            toggleOpen()
            setUserInput('')
            setSelectedValue(containerValue)
            onChange(containerValue)
            return
        }

        // set selected filter state to preserve filter values between screens
        setSelectedFilters({
            ...selectedFilters,
            [filterLabel]: optionValue, // use label because id is page specific, label is universal
        });

        toggleOpen();
        setSelectedValue(value);
        onChange(value);
    };

    const onInputChange = (inputValue, { action }) => {

        if (action === 'input-change') {
            setUserInput(inputValue);
        }

        if (action === 'input-blur' || action === 'menu-close') {
            setUserInput('')
        }
    }

    function isDisabled(options) {
        return !options || options.length === 0
    }

    if (!options || options.length < 5 || isMulti) {
        if ((options.length > 5 && isMulti) || isHcpName) {
            return (
                // windowed select for improved performance with large multi-selects
                <WindowedSelect 
                    value={_selectedValue}
                    inputValue={userInput}
                    isMulti={true}
                    onChange={onSelectChange}
                    onInputChange={onInputChange}
                    styles={selectStyles}
                    options={options}
                    placeholder="Search..."
                    filterOption={filterOption}
                    components={enhancedComponents}
                    noOptionsMessage={() => "Type to search"}
                    {...props}
                />
            )
        }
        return (
            <Select
                isSearchable={false}
                components={{ IndicatorSeparator: null, DropdownIndicator: ChevronDown, ...enhancedComponents }}
                onChange={onSelectChange}
                options={options}
                placeholder={isDisabled(options) ? "No options" : "Select..."}
                styles={selectStyles}
                value={_selectedValue}
                isDisabled={isDisabled(options)}
                isMulti={isMulti}
                {...props}
            />
        );
    }

    return (

        <div>
            <InnerDropDown onClick={toggleOpen}>
                {_selectedValue ? `${_selectedValue.label?.trim()}` : 'Select'}
                <ChevronDown />
            </InnerDropDown>
            {isOpen && (
                <Menu>
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{ IndicatorSeparator: null, DropdownIndicator: null, ...components }}
                        controlShouldRenderValue={false}
                        menuIsOpen
                        onChange={onSelectChange}
                        options={options}
                        placeholder="Search..."
                        styles={selectStyles}
                        value={_selectedValue}
                        isMulti={isMulti}
                        {...props}
                    />
                </Menu>)}
            {isOpen && <Blanket onClick={toggleOpen} />}
        </div>
    );
}
