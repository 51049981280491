import { InteractionRequiredAuthError } from "@azure/msal-common";
import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

const apiUrl = process.env.REACT_APP_API_URL;
const appId = process.env.REACT_APP_AUTH_APP_ID;
const samlUrl = process.env.REACT_APP_SAML_URL;
const samlEnabled = samlUrl ? true : false;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const tokenParam = urlParams.get('token');

if (tokenParam) {
    localStorage.setItem('token', tokenParam);

    const urlNoParams = window.location.origin + window.location.pathname;
    window.location.replace(urlNoParams);
}

const token = localStorage.getItem('token');

function pathUrl(path) {
    return `${apiUrl}${path}`;
}

export function samlRedirect() {
    const url = pathUrl(`${samlUrl}?redirectTo=${encodeURIComponent(window.location.href)}`);
    window.location.replace(url);
}

export async function getLoggedInUser(){
    return await apiGet('user/whoami');

}

export async function apiGet(path) {
    
    const response = await fetch(pathUrl(path), {
        credentials: "include"
    });

    if (response.status === 401 && samlEnabled)
        samlRedirect()

    if (!response.ok)
        throw response.statusText;

    return await response.json();
}

export async function apiGetRaw(path) {
    try {
        const response = await fetch(pathUrl(path), {
            credentials: "include"
        });

        if (response.status === 401 && samlEnabled)
            samlRedirect()

        if (!response.ok)
            throw response.statusText;

        const dataBlob = await response.blob();
        const dataUrl = URL.createObjectURL(dataBlob);

        const filename = getFileNameFrom(response.headers);

        var anchor = document.createElement('a');
        anchor.href = dataUrl;
        anchor.download = filename || `${path}.xlsx`;
        document.body.appendChild(anchor); // we need to append the element to the dom -> otherwise it will not work in firefox
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(dataUrl);

    }
    catch (err) {
        console.error(err);
        throw err;
    }
}

export async function apiPost(path, body) {
    
    const response = await fetch(pathUrl(path), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body)
    });

    if (response.status === 401 && samlEnabled)
        samlRedirect()

    if (!response.ok){
        throw response.statusText || response.status;
    }
    
    return await response.json();
}

export async function apiPostRaw(path, body) {
    
    const response = await fetch(pathUrl(path), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body)
    });

    if (response.status === 401 && samlEnabled)
        samlRedirect()

    const filename = getFileNameFrom(response.headers);


    if (!response.ok)
        throw response.statusText;

    const dataBlob = await response.blob();
    const dataUrl = URL.createObjectURL(dataBlob);

    var anchor = document.createElement('a');
    anchor.href = dataUrl;
    anchor.download = filename || `${path}.xlsx`;
    document.body.appendChild(anchor); // we need to append the element to the dom -> otherwise it will not work in firefox
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(dataUrl);
}

export async function apiPut(path, body) {
    
    const response = await fetch(pathUrl(path), {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body)
    });

    if (response.status === 401 && samlEnabled)
        samlRedirect()

    if (!response.ok)
        throw response.statusText;
}

export async function apiDelete(path) {
    
    const response = await fetch(pathUrl(path), {
        method: 'DELETE',
        credentials: "include"
    });

    if (response.status === 401 && samlEnabled)
        samlRedirect()

    if (!response.ok)
        throw response.statusText;
}

/**
 * A function which will retrieve the file name from the given headers
 * @param {Headers} headers the JavaScript WebAPI headers taken from the response
 * @returns a string representing the file name or null if no filename could be found
 */
function getFileNameFrom(headers) {

    if (!headers) {
        return null;
    }

    const dispositionHeaderValue = headers.get('Content-Disposition');

    if (!dispositionHeaderValue || typeof dispositionHeaderValue !== 'string') {
        return null;
    }

    const headerValueSplit = dispositionHeaderValue.split('filename=') || [];

    if (!headerValueSplit || headerValueSplit.length < 1) {
        return null;
    }

    const semiColonSplit = headerValueSplit[1].split(';') || [];

    if (!semiColonSplit || semiColonSplit.length === 0) {
        return null;
    }

    // clean string of quotes as this was introducing odd behavior with Chrome wrapping with underscores
    let cleanedValue = dispositionHeaderValue.replaceAll('"', '').replaceAll(' ', '_');

    return cleanedValue.split('filename=')[1].split(';')[0];
}
