import RepMessagesReportContainer from '../components/RepMessagesReportContainer';
import { reports } from '../reports';
import { useTheme } from "styled-components";

function RepMessages() {
	const theme = useTheme();
	return (
		<RepMessagesReportContainer
			reportId={reports.repMessages.id}
			hcpInfoReportId={reports.hcpInfo.id}
			payerMixReportId={reports.hcpPayerMix.id}			
			entityAlias={theme.aliases.hcp}
			hcpType={"Prescriber"}
		/>
	);
}

export default RepMessages;
