import React, { useState, createContext } from 'react';

const initState = {};

export const SelectedFiltersContext = createContext({selectedFilters: initState});

export const SelectedFiltersContextProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState(initState);

  return (
    <SelectedFiltersContext.Provider value={{ selectedFilters, setSelectedFilters }}>
      {children}
    </SelectedFiltersContext.Provider>
  );
}