export function numberString(number) {
    if (number === undefined || number === null)
        return '';
    number = number || 0;
    if (typeof number === 'string')
        number = parseFloat(number);
    if (typeof number !== 'number')
        throw new Error(`${number} is not a number`);

    return number.toLocaleString(undefined, { maximumFractionDigits: 0 });
}

export function percentString(number) {
    if (number === undefined || number === null)
        return '';
    number = number || 0;
    if (typeof number === 'string')
        number = parseFloat(number);
    if (typeof number !== 'number')
        throw new Error(`${number} is not a number`);

    return number.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
}
