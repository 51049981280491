export const reports = {
	geoLandscape: {
		id: 'geographic_landscape',
		path: '/GeographicLandscape',
	},
	payerLandscape: {
		id: 'payer_landscape',
		path: '/PayerLandscape',
	},
	payerTopHcps: {
		id: 'payer_top_hcps',
		path: '/PayerTopHCPs',
	},
	repMessages: {
		id: 'hcps',
		path: '/RepMessages',
	},
	hcpOpportunityScore: {
		id: 'prescriber_opportunity',
		path: '/HCPOpportunityScore',
	},
	payerTopGroupPractices: {
		id: 'payer_top_group_practices',
		path: '/PayerTopGroupPractices',
	},
	payerLandscapeGroupPractice: {
		id: 'payer_landscape_gp',
		path: '/PayerLandscapeGroupPractice',
	},
	payerLandscapeTopHcps: {
		id: 'payer_landscape_top_hcps'
	},
	payerLandscapeTopGroupPractices: {
		id: 'payer_landscape_gp_top_group_practices'
	},
	payerLandscapeFormularies: {
		id: "payer_landscape_formulary"
	},
	payerLandscapeGroupPracticeFormularies: {
		id: "payer_landscape_gp_formulary"
	},
	repMessagesGroupPractice: {
		id: 'kam_messages',
		path: '/KAMMessages',
	},
	hcpInfo: {
		id: 'hcp_stats',
	},
	groupPracticeInfo: {
		id: 'group_practice_stats',
	},
	hcpPayerMix: {
		id: 'hcp_payer_mix',
	},
	groupPracticePayerMix: {
		id: 'group_practice_payer_mix',
	},
	hcpMessages: {
		id: 'hcp_messages',
	},
	groupPracticeMessages: {
		id: 'group_practice_messages',
	},
	hcpTop5Plans: {
		id: 'hcp_top_5',
	},
	groupPracticeTop5Plans: {
		id: 'group_practice_top_5',
	},
	hcpMessagesPod2: {
		id: 'hcp_messages_pod2',
	},
	groupPracticeMessagesPod2: {
		id: 'group_practice_messages_pod2',
	},
	hcpTop5PlansPod2: {
		id: 'hcp_top_5_pod2',
	},
	groupPracticeTop5PlansPod2: {
		id: 'group_practice_top_5_pod2',
	},
};
