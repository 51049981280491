import { EventType, PublicClientApplication } from "@azure/msal-browser";

import App from './App';
import MsalApp from './MsalApp';
import { msalConfig, nonSsoAuthority, ssoAuthority } from './authConfig';
import { theme } from "./theme";

const nonSsoLogin = window.location.pathname === '/login';

const msalInstance = new PublicClientApplication({
    ...msalConfig,
    auth: {
        ...msalConfig.auth,
        authority: nonSsoLogin ? nonSsoAuthority : ssoAuthority
    }
});

if (nonSsoLogin && msalInstance.getActiveAccount()) {
    // Force them to sign out if they came into /login while already signed in
    msalInstance.logoutRedirect({
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/login`
    });
}

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(function (event) {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});


export {
    App,
    MsalApp,
    msalInstance,
    theme as defaultTheme
};
