import React, { useCallback, useContext, useMemo, useReducer } from 'react';

import Map from '../components/Map';
import Legend from '../components/Legend';
import { Report, useReport } from '../components/Report';
import AppContext from '../AppContext';
import { init, reducer, PUSH_HISTORY, SET_MAIN_GEOGRAPHY } from '../reducers/geographic-landscape.reducer';
import { numberString, percentString } from '../util/formatting';

import { geoFilters } from '../util/geoFilters';
import styled, { useTheme } from 'styled-components';

import { getBoundingBox, getBoundingBox2 } from '../api/geography'
import { SelectedFiltersContext } from "../util/SelectedFiltersContext";
import {reports} from '../reports';

const MapContainer = styled.div`
    position: relative;

    .maplibregl-map {
      width: 66.6vh;
      height: 50vh;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
    }

    .maplibregl-popup,
    .maplibregl-popup-content,
    .mapboxgl-popup-content {
      pointer-events: none;
    }
`;

const MapLegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const BreadcrumbContainer = styled.div`
    display: flex;
    flex-direction: row;
    left: 0;
    bottom: 0;
    border-radius: 0px 10px 0px 0px;
    background-color: white;

    > * {
        margin-right: 0.3rem;
        color: #7B7B7B;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;

        &::before {
            content: ' > ';
            position: relative;
            top: 3px;
            font-size: x-large;
        }
    
        &:first-child::before {
            content: '';
        }
    }
`;


function uppercasePropertiesObject(object) {
    return Object.fromEntries(Object.entries(object).map(([name, value]) => [name.toUpperCase(), value]));
}


function finiteOrNull(number) {
    return Number.isFinite(number) ? number : null;
}
function parseCategoryRanges(palette, mapLegendValues, heatMeasure, data, geographyKind, heatCategories) {
    const productID = data && data.length && data[0].PRODUCTID ? data[0].PRODUCTID : null;
    const heatMeasureProperty = heatMeasure && heatMeasure.property ? heatMeasure.property : null;
    const benTypeFilter = data && data.length && data[0].BENTYPEFILTER ? (data[0].BENTYPEFILTER).toUpperCase() : null;

    if (!productID || !heatMeasureProperty || !geographyKind) return null;

    mapLegendValues = uppercasePropertiesObject(mapLegendValues);

    // check for MapLegendValue with BenTypeFilter first
    // let legendParams = `${productID}|${heatMeasureProperty}|${geographyKind}`; // to fix crash (quick fix)
    let legendParams = `${productID}|${heatMeasureProperty}|${geographyKind}|${benTypeFilter}`;
    let legendValues = mapLegendValues[legendParams];
    // if it doesn't exist, check for one without BenTypeFilter
    if (!legendValues){
        legendParams = `${productID}|${heatMeasureProperty}|${geographyKind}|`;

        // quick fix of 'High' crash error, NEED TO FIX DATA
        // if mapLegendValues properties don't end with '|' and legendParams does...
        if (Object.keys(mapLegendValues)[0].slice(-1) !== '|' && legendParams.slice(-1) === '|') {

            // ...make them aligned
            legendParams = legendParams.slice(0, -1);
        }

        legendValues = mapLegendValues[legendParams];
    }
    
    if (!mapLegendValues) {
        console.warn(`No mapLegendValues for '${legendParams}'`);
        legendValues = {};
    }  

    return heatCategories.map((c, i) => ({
        id: c.id,
        label: c.label,
        color: palette[i % palette.length],
        low: finiteOrNull(legendValues[c.id] ? legendValues[c.id].LowerLimit : null),
        high: finiteOrNull(legendValues[c.id] ? legendValues[c.id].UpperLimit : null)
    }));
}

const GeographicLandscape = () => {
    const { configuration } = useContext(AppContext);
    const theme = useTheme();
    const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
    const [state, dispatch] = useReducer(reducer, configuration, init);
    const geographySelection = state.geographySelection;

    const { mainGeography, history } = geographySelection;
    const {
        //hierarchy: configHierarchy,
        mapStyle,
        geoLevelOptions,
        geographyKinds,
        heatMeasures,
        heatCategories,
        mapLegendValues,
        apiGeographySupport = false,
        skipNationalAsDefaultGeography
    } = configuration || {};

    const recordGeographyKindId = useCallback(record => {
        // TODO Hardcoded column GEOGRAPHYKINDID
        const geographyKindId = record.GEOGRAPHYKINDID;
        if (geographyKindId !== undefined)
            return geographyKindId;

        // TODO Hardcoded column GEOLEVEL
        const geoLevel = record.GEOLEVEL;
        for (const gk of geographyKinds) {
            if (gk.label === geoLevel) {
                return gk.id;
            }
        }

        return undefined;
    },
        [geographyKinds])

    const nullGeography = useMemo(() => {
        const geoLevelOpt = geoLevelOptions.find(opt => opt.value === mainGeography.kind);
        const nullGeoOption = geoLevelOpt?.options?.find(opt => opt.value === null) || null;
        return nullGeoOption && {
            id: nullGeoOption.value,
            name: nullGeoOption.label,
            kind: mainGeography.kind
        };
    }, [geoLevelOptions, mainGeography]);


    const setMainGeography = useCallback(value => dispatch({
        type: SET_MAIN_GEOGRAPHY,
        value: value
    }), [dispatch]);

    

    // Set the special geography up/down filtering
    const geoLevelFilters = useMemo(() => geoFilters(history, mainGeography, true),
        [history, mainGeography]);

    const report = useReport({
        reportId: reports.geoLandscape.id,
        filters: geoLevelFilters,
        queryOptions: {
            pageSize: 550
        }
    });

    const { data, options } = report;

    const geographicFilter= useMemo(() => {
        if(!report?.configuration){
            return null;
        }
        return report.configuration.filters.find(f => f.filterKind === 'geography-type');
    }, [report.configuration]);

    const updateGeoFilters = useCallback((kind, id) => {
        setSelectedFilters({ ...selectedFilters, [geographicFilter.label?.trim().toLowerCase()]: kind, [geographicFilter.geographyLabel?.trim().toLowerCase()]: id});
     }, [geographicFilter, setSelectedFilters, selectedFilters]);

    const onSelectGeography = useCallback(value => {
        if (value === null) {
            setMainGeography(nullGeography);
            updateGeoFilters(null, null);
            return;
        }
        dispatch({
            type: PUSH_HISTORY,
            item: value
        });
        
        if(state.geographySelection.mainGeography.kind === value.kind){
            // set selected filters so they are kept through pages
            updateGeoFilters(value.kind, value.id);
        }
        
        
    }, [dispatch, nullGeography, setMainGeography, state.geographySelection, updateGeoFilters, selectedFilters, setSelectedFilters, geographicFilter]);

    const selectedHeatMeasure = options && options.heatMeasure && heatMeasures && heatMeasures[options.heatMeasure];
    const heatMeasureRenderer = useMemo(() => {
        switch (selectedHeatMeasure?.kind) {
            case 'number': return numberString;
            case 'percent': return percentString;
            default:
                console.warn(`No known renderer for ${selectedHeatMeasure?.kind}`, selectedHeatMeasure);
                return x => x;
        }
    }, [selectedHeatMeasure]);

    const geographyKindId = mainGeography && mainGeography.kind ? mainGeography.kind : null;
    const { heatMeasureRanges, heatMeasureValues } = useMemo(() => {
        if (!heatCategories || !data)
            return {};

        const ranges = parseCategoryRanges(theme.palette, mapLegendValues, selectedHeatMeasure, data, geographyKindId, heatCategories);

        const values = [];
        for (const record of data) {
            // TODO Hardcoded column GEOGRAPHYID
            const id = record.GEOGRAPHYID;
            const geographyKindId = recordGeographyKindId(record);

            if (id !== undefined && geographyKindId !== undefined) {
                values.push([
                    id,
                    geographyKindId,
                    record[selectedHeatMeasure.property],
                    record[selectedHeatMeasure.categoryProperty]
                ]);
            }
        }

        return {
            heatMeasureRanges: ranges,
            heatMeasureValues: values
        };
    }, [heatCategories, data, selectedHeatMeasure, theme, recordGeographyKindId, mapLegendValues, geographyKindId]);

    const legendItems = useMemo(() => {
        if (!heatMeasureRanges || !selectedHeatMeasure)
            return [];
        return heatMeasureRanges.map(r => ({
            color: r.color,
            label: r.label,
            subLabel: `${heatMeasureRenderer(r.low)} - ${heatMeasureRenderer(r.high)}`
        }));
    }, [heatMeasureRanges, heatMeasureRenderer, selectedHeatMeasure]);

    const geographyKindIds = useMemo(() => geographyKinds.map(gk => gk.id), [geographyKinds]);

    const mapGetBoundingBox = useMemo(() => apiGeographySupport ? (_, id) => getBoundingBox2(id) : getBoundingBox,
        [apiGeographySupport]
    );

    if (!configuration)
        return <></>;

    let activeGeographies = [];
    if (report.data) {
        for (const r of report.data) {
            // TODO Hardcoded column GEOGRAPHYID
            const id = r.GEOGRAPHYID;
            const geographyKindId = recordGeographyKindId(r);

            if (id !== undefined && geographyKindId !== undefined) {
                activeGeographies.push({
                    id,
                    kind: geographyKindId
                });
            }
        }
    }

    return (
        <Report
            report={report}
            mainGeography={mainGeography}
            setMainGeography={setMainGeography}
            geoLevelOptions={geoLevelOptions}
            skipNationalAsDefaultGeography={skipNationalAsDefaultGeography}>
            <MapContainer>
                <MapLegendContainer>
                    <Map id='geographic-landscape-map'
                        getBoundingBox={mapGetBoundingBox}
                        heatMeasureValues={heatMeasureValues}
                        heatMeasureRanges={heatMeasureRanges}
                        heatMeasureRenderer={heatMeasureRenderer}
                        geographySelection={geographySelection}
                        onSelectGeography={onSelectGeography}
                        style={mapStyle}
                        geographyKindIds={geographyKindIds}
                        mainGeography={mainGeography}
                        activeGeographies={activeGeographies} />
                    {/* LEGEND */}
                    <Legend legendItems={legendItems} isHorizontal={true} />
                </MapLegendContainer>
                <BreadcrumbContainer>
                    {mainGeography.id && nullGeography && (
                        <div onClick={() => setMainGeography(nullGeography)}>
                            {nullGeography.name}
                        </div>
                    )}
                    <div onClick={() => onSelectGeography(null)}>
                        {mainGeography.name}
                    </div>
                    {state.geographySelection.history.map(elt => (
                        <div key={elt.id} onClick={() => onSelectGeography(elt)}>
                            {elt.name}
                        </div>))}
                </BreadcrumbContainer>
            </MapContainer>
        </Report>
    );
};

export default GeographicLandscape;
