import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState
} from 'react'
import mapboxgl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css';

import { getBoundingBox } from '../api/geography';
import usePrevious from '../util/usePrevious';
import calculateFeaturesBoundingBox from '../util/calculateFeaturesBoundingBox';

import equal from 'fast-deep-equal';

const bounds = [
    [-24.395421261280717, -17.177455841380464],
    [21.62552599755014, 16.82771768316543]
];

const center = [(bounds[1][0] - bounds[0][0] / 2), (bounds[1][1] - bounds[0][1] / 2)];

const initialState = {
    map: null,
    mapStyleLoaded: false,
    hoverFeature: null
}

const SET_STATE = 'SET_STATE';
const SET_HOVER = 'SET_HOVER';

function reducer(state, action) {
    switch (action.type) {
        case SET_STATE:
            return {
                ...state,
                ...action.state
            };
        case SET_HOVER:
            const newHoverFeature = action.payload;
            if (state.hoverFeature?.id === newHoverFeature?.id)
                return state;

            return {
                ...state,
                hoverFeature: newHoverFeature
            };
        default:
            console.warn('Unknown action', action);
            return state;
    }
}

function Map({
    id,
    getBoundingBox,
    heatMeasureValues: inputHeatMeasureValues = [],
    heatMeasureRanges,
    heatMeasureRenderer = x => '' + x,
    geographySelection,
    onSelectGeography,
    highlightColor = 'rgb(255, 204, 102)',
    geographyKindIds: inputGeographyKindIds,
    style,
    mainGeography,
    activeGeographies = []
}) {
    // Coerce the hierarchy into strings as MapBoxGL canonically uses strings for layer ids
    const geographyKindIds = useMemo(() => inputGeographyKindIds.map(h => h.toString()), [inputGeographyKindIds]);
    const geographyKindLayerIds = useMemo(() => {
        //Only include layers we actually have available
        return geographyKindIds.filter(gkId => style.layers.some(l => l.id === gkId));
    }, [style, geographyKindIds]);

    //Coerce heatMeasureValues into strings for geography & layer ids
    const heatMeasureValues = useMemo(() => inputHeatMeasureValues.map(([id, gk, ...rest]) => [id.toString(), gk.toString(), ...rest]),
        [inputHeatMeasureValues]);

    const [state, dispatch] = useReducer(reducer, initialState);
    const setState = useCallback(newState => dispatch({
        type: SET_STATE,
        state: newState
    }), []);
    const popup = useRef(null);
    const map = state.map;
    const setMap = useCallback(value => setState({ map: value }), [setState]);
    const hoverFeature = state.hoverFeature;
    const previousHoverFeature = usePrevious(hoverFeature);
    const setHoverFeature = value => dispatch({
        type: SET_HOVER,
        payload: value
    });


    // Hover feature updated
    useEffect(() => {
        if (!map)
            return;

        if (previousHoverFeature && previousHoverFeature.id && (!hoverFeature || previousHoverFeature.id !== hoverFeature.id))
            map.setFeatureState(previousHoverFeature, { hover: false });


        if (!hoverFeature) {
            if (popup.current) {
                popup.current.remove();
                popup.current = null;
            }
            return;
        }

        if (!popup.current) {
            popup.current = new mapboxgl.Popup({
                closeButton: false,
            });
        }

        if (hoverFeature.id)
            map.setFeatureState(hoverFeature, { hover: true });

        let description = hoverFeature.properties.name;
        const heatMeasureValue = map.getFeatureState(hoverFeature).heatMeasure;
        if (heatMeasureValue !== null) {
            const renderedValue = heatMeasureRenderer(heatMeasureValue);
            description += ': ' + renderedValue;
        }

        popup.current
            .setHTML(description)
            .addTo(map);

    }, [map, hoverFeature, previousHoverFeature, heatMeasureRenderer]);

    const { history } = geographySelection;
    const hoverHighlightOrLightGray = useMemo(() => [
        'case',
        ['boolean', ['feature-state', 'hover'], false], highlightColor,
        'lightgray'
    ],
        [highlightColor]);

    const hoverHighlightOrDarkGray = useMemo(() => [
        'case',
        ['boolean', ['feature-state', 'hover'], false], highlightColor,
        'darkgray'
    ],
        [highlightColor]);

    const heatMeasureColor = useMemo(() => {
        return [
            'case',
            ['boolean', ['feature-state', 'hover'], false], highlightColor,
            ['==', ['feature-state', 'heatCategory'], null], 'gray',
            ...(heatMeasureRanges || []).reduce((arr, { id, color }) => {
                return [
                    ...arr,
                    ['==', id, ['feature-state', 'heatCategory']],
                    color
                ];
            }, []),
            'red'
        ];
    }, [highlightColor, heatMeasureRanges]);

    const previousActiveGeographies = usePrevious(activeGeographies);
    useEffect(() => {
        if (!map) return;

        if (previousActiveGeographies && !equal(previousActiveGeographies, activeGeographies)) {
            for (const g of previousActiveGeographies) {
                const { kind } = g;
                //TODO Hardcoded NATIONAL kind
                if (kind === 100000000000)
                    continue;

                const layerId = kind.toString();
                const layer = map.getLayer(layerId);
                if (layer) {
                    map.setFilter(layer.id, false);
                }

                const labelsLayer = map.getLayer(`${layerId}_labels`);
                if (labelsLayer)
                    map.setFilter(labelsLayer.id, false);
            }
        }

        const geographiesPerKind = {};
        for (const g of activeGeographies) {
            const { id, kind } = g;

            // TODO Hardcoded NATIONAL kind
            if (kind === 100000000000)
                continue;

            let arr = geographiesPerKind[kind];
            if (!arr) {
                geographiesPerKind[kind] = arr = [];
            }

            arr.push(id.toString());
        }

        
        const topLayerId = activeGeographies[activeGeographies.length - 1]?.kind.toString();
        for (const [layerId, ids] of Object.entries(geographiesPerKind)) {
            // filter all layers but the top one (the one in front), so only the labels for that layer are visible and not the ones from layers below it.
            const filter = layerId === topLayerId ?  ['in', ['string', ['get', 'id']], ['literal', ids]] : false;
            const layer = map.getLayer(layerId);
            if (layer) {
                map.setFilter(layer.id, filter);
            }

            if (!history.find(elt => elt.kind.toString() === layerId)) {
                const labelsLayer = map.getLayer(`${layerId}_labels`);
                if (labelsLayer){
                    map.setFilter(labelsLayer.id, filter);
                }
            }
        }

    }, [map, activeGeographies, previousActiveGeographies, history]);

    // Selection changed
    useEffect(() => {
        if (!map)
            return;

        let unmounted = false;
        let cancel = null;

        const activeSelection = history.length !== 0 ? history[history.length - 1] : mainGeography.id ? mainGeography : null;

        if (activeSelection) {
            const { kind, id } = activeSelection;

            const fitBoundsFromMapData = () => {
                // TODO hardcoded azure source
                const features = map.querySourceFeatures('azure', {
                    sourceLayer: kind,
                    filter: ['==', ['get', 'id'], id.toString()]
                });

                if (features.length > 0) {
                    const bbox = calculateFeaturesBoundingBox(features);
                    map.fitBounds(bbox);
                    return true;
                }

                return false;
            };

            // Make an API call for the bounding bx 
            // this is more accurate than any client-side data we might have loaded
            getBoundingBox(kind, id)
                .then(bbox => {
                    if (unmounted)
                        return;
                    if (cancel)
                        cancel();
                    map.fitBounds(bbox);
                })
                .catch(_ => {
                    if (unmounted)
                        return;

                    if (!fitBoundsFromMapData()) {
                        // zoom out and try again a few times
                        map.fitBounds(bounds);
                        let retries = 24;
                        const interval = setInterval(() => {
                            if (--retries <= 0) {
                                // Give up
                                clearInterval(interval);
                                return;
                            }

                            if (fitBoundsFromMapData()) {
                                clearInterval(interval);
                            }
                        }, 500);

                        cancel = () => clearInterval(interval);
                    }
                });
        }
        else {
            map.fitBounds(bounds);
        }

        return () => {
            unmounted = true;
            if (cancel) cancel();
        };

    }, [map, history, mainGeography]);

    const previousHeatMeasureValues = usePrevious(heatMeasureValues);

    // measure value changed
    useEffect(() => {
        if (!map || !heatMeasureValues)
            return;

        if (previousHeatMeasureValues) {
            for (const [id, layer] of previousHeatMeasureValues) {
                map.setFeatureState(
                    {
                        id: id,
                        source: 'azure',
                        sourceLayer: layer
                    },
                    {
                        heatMeasure: undefined,
                        heatCategory: undefined
                    });
            }
        }

        for (const [id, layer, value, category] of heatMeasureValues) {
            map.setFeatureState(
                {
                    id: id,
                    source: 'azure',
                    sourceLayer: layer
                },
                {
                    heatMeasure: value,
                    heatCategory: category
                });
        }

    }, [map, heatMeasureValues, previousHeatMeasureValues]);

    // Handle updated heatmap measure
    useEffect(() => {
        if (!map)
            return;

        for (const elt of geographyKindLayerIds) {
            map.setPaintProperty(elt, 'fill-color', heatMeasureColor);
        }

        // TODO Hardcoded geography kind NATIONAL
        map.setPaintProperty('100000000000', 'fill-color', hoverHighlightOrDarkGray);
        const prevSelection = history.length > 1 ? history[history.length - 2] : null;
        if (prevSelection) {
            map.setPaintProperty(prevSelection.kind.toString(), 'fill-color', hoverHighlightOrLightGray);
        }

    }, [map, geographyKindLayerIds, heatMeasureColor, hoverHighlightOrLightGray, hoverHighlightOrDarkGray, mainGeography, history]);

    useEffect(() => {
        if (!map)
            return;

        const onMapClick = e => {
            const features = map.queryRenderedFeatures(e.point, {
                'layers': geographyKindIds
            });

            const feature = features.find(f => f.id !== undefined);

            if (!feature) {
                onSelectGeography(null);
                return;
            }

            const geographyId = Number.parseInt(feature.id);
            if (Number.isNaN(geographyId)) {
                return;
            }

            const geographyKindId = Number.parseInt(feature.layer.id);
            if (Number.isNaN(geographyKindId)) {
                return;
            }

            // TODO Hardcoded geography kind NATIONAL
            if (geographyKindId === 100000000000) {
                onSelectGeography(null);
                return;
            }

            onSelectGeography({
                kind: geographyKindId,
                id: geographyId,
                name: feature.properties.name
            });
        };

        map.on('click', onMapClick);

        return () => map.off('click', onMapClick);
    }, [map, geographyKindIds, onSelectGeography]);

    useEffect(() => {
        if (!map)
            return;

        const onMouseMove = e => {
            const features = map.queryRenderedFeatures(e.point, {
                layers: geographyKindIds
            });

            const feature = features.find(f => f.id !== undefined);

            if (!feature) {
                setHoverFeature(null);
                return;
            }

            setHoverFeature(feature);

            if (popup.current)
                popup.current.setLngLat(e.lngLat);
        };

        map.on('mousemove', onMouseMove);

        return () => map.off('mousemove', onMouseMove);

    }, [map, geographyKindIds]);

    const setMapContainer = useCallback(container => {
        if (!container){
            return;
        }
        
        const map = new mapboxgl.Map({
            container: container,
            center: center, // starting position [lng, lat],
            dragRotate: false,
            touchPitch: false,
            pitchWithRotate: false,
            renderWorldCopies: false,
            maxBounds: bounds,
            maxZoom: 8.5,
            style: style,
            transformRequest: (url, resourceType) => {
                // Supply app key if required
                if (url.indexOf(process.env.REACT_APP_API_URL) > -1) {
                    return {
                        url: url,
                        credentials: "include"                        
                    }
                }
            }
        });

        map.on('style.load', () => setMap(map));
        window.map = map;
    }, [style, setMap]);
    // }, [style, setMap]);

    return (
        <div id={id} ref={setMapContainer} />
    );
};

export default Map;
