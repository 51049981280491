export function childHierarchy(hierarchy, kind) {
    const idx = hierarchy.indexOf(kind);
    if (idx === -1 || idx === hierarchy.length - 1)
        return null;

    return hierarchy[idx + 1];
}

export function geoFilters(history, mainGeography, requireId) {
    const activeItem = history.length > 0 ? history[history.length - 1] : mainGeography;

    if (!requireId) {
        if (!mainGeography.id) {
            // TODO Hardcoded national geography ID for pages that don't allow a 'null'
            // geography ID (every page other than GeographicLandscape)
            return ({
                // TODO Hardcoded column GeographyID
                "@@GEOGRAPHYID@@": undefined,
                "@@GEOGRAPHYKINDID@@": undefined,
                "GeographyID": 100000000001
            });
        }

        return ({
            "@@GEOGRAPHYID@@": undefined,
            "@@GEOGRAPHYKINDID@@": undefined,
            // TODO Hardcoded column GeographyID
            "GeographyID": mainGeography.id
        });
    }

    if (activeItem.id === null) {
        return ({
            "@@GEOGRAPHYID@@": undefined,
            "@@GEOGRAPHYKINDID@@": activeItem.kind,
            "GeographyID": undefined
        });
    }
    return ({
        // TODO Hack filter to get geographies to filter 1 up 1 down
        "@@GEOGRAPHYID@@": activeItem.id,
        "@@GEOGRAPHYKINDID@@": undefined,
        "GeographyID": undefined
    });

}

