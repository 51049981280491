import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { NavLink } from "react-router-dom";
import styled, { css, keyframes, useTheme } from "styled-components";
import { logoutRequest } from '../authConfig';

function acceptsExpanded(props) {
    return {
        $expanded: !!props.$expanded
    };
}

const showExpandedMixin = css`
    visibility: visible;
    opacity: 1;
    transition: margin .2s, visibility 0s linear 0s, opacity .1s linear .2s;
`;

const showCollapsedMixin = css`
    visibility: hidden;
    opacity: 0;
    width: 0;
    margin: 0;          
    transition: margin .2s, visibility 0s linear 300ms, opacity 0s;
`;

const showOnExpandMixin = css`
    ${props => props.$expanded ? showExpandedMixin : showCollapsedMixin}
`;

const Logo = styled.img.attrs(acceptsExpanded)`
    width: ${props => props.theme.css.sidebarLogoWidth};
    height: ${props => props.theme.css.sidebarLogoHeight};
    margin-top: ${props => props.theme.css.sidebarLogoMarginTop};
    margin-left: ${props => props.theme.css.sidebarLogoMarginLeft};
    display: ${props => props.$expanded ? 'block' : 'none'};
`;

const Header = styled.div.attrs(acceptsExpanded)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${showOnExpandMixin}
    padding-bottom: 2rem;
    padding-top: 10px;
    h1,
    span {
        filter: ${props => props.theme.colors.sidebarIconFilter}
    }
`;

const ListContainer = styled.div.attrs(acceptsExpanded)`
    flex: auto;
    width: 100%;
    position: relative;
    > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin-right: ${props => props.$expanded ? `2rem` : `0em`};
            display: flex;
            align-items: center;
        }
    }
`;

const rightLeftBounce = keyframes`
    0%,
    100% {
      transform: translateX(0);
    }
  
    50% {
        transform: translateX(-100%);
    }
`;

const ExpandIcon = styled.div.attrs(acceptsExpanded)`
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 50%;
    transition: .5s;
    pointer-events: none;
    right: 200%;
    img, object {
            filter: ${props => props.theme.colors.sidebarIconFilter};
            float: right;
            transition: .5s;
            ${props => !props.$expanded && css`
                transform: rotate(180deg);
            `}
    }

    ${props => {
        if (!props.$expanded)
            return;

        return css`
            right: .5rem;
            pointer-events: unset;

            &:hover > * {
                animation: ${rightLeftBounce} 2s infinite;
            }
        `;
    }}
`;

const HorizontalLine = styled.div.attrs(acceptsExpanded)`
    position: relative;
    height: 1px;
    width: 100%;
    background: gray;
    margin: 2rem auto;

    ${showOnExpandMixin}
`;

const ShowOnExpand = styled.div.attrs(acceptsExpanded)`
    ${showOnExpandMixin}
`;

const LinkStyle = css`
    color: white;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    img, object {
        padding-left: .5rem;
        margin-right: 7px;
    }

    &:hover {
        background:  ${props => props.theme.colors.sidebarIconHoverBackground};
    }

    img,
    object
    span {
        filter: ${props => props.theme.colors.sidebarIconFilter};
    }

    &.${props => props.activeClassName} {
        img,
        object,
        span {
            filter: ${props => props.theme.colors.sidebarIconSelectedFilter};
        }
    }
`;

const InternalLink = styled(NavLink)`
    ${LinkStyle}
`;

const ExternalLink = styled.a`
    ${LinkStyle}
`;


const SideBarNav = styled.nav.attrs(acceptsExpanded)`
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: absolute;
    color: white;
    left: 0;
    top: 0;
    height: 100%;
    transition: .3s;
    cursor: pointer;
    width: 3rem;
    padding-right: 0;

    &:hover {
        &:before {
            transform: scaleX(2.0);
        }

        ${ExpandIcon} {
            right: .25rem;
            transform: scale(1.3);
        }
    }

    &:before {
        content: "";
        background: ${props => props.theme.colors.sidebar};

        height: 100%;
        width: 100%;

        position: absolute; 
        transition: all .2s ease-in-out; 
    }
    
    ${props => {
        if (!props.$expanded)
            return;

        return css`
            width: 20rem;

            &:hover{
                &:before {
                    transform: unset;
                }
                ${ExpandIcon} {
                    right: .5rem;
                    transform: unset;
                }
            }
        `;
    }}
`;

const LogoutButton = styled.button.attrs(acceptsExpanded)`
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #393939;
    border-radius: 0.3rem;
    background: ${props => props.theme.colors.logoutBackground};
    padding: 0.75rem 1.5rem;
    color: white;
    position: relative;
    margin-bottom: 2rem;

    &:hover {
        background: ${props => props.theme.colors.logoutBackgroundHover};
    }

    span {
        filter: ${props => props.theme.colors.sidebarIconFilter};
    }

    ${showOnExpandMixin}
`;

function SideBar({
    account,
    mainLinks,
    secondaryLinks,
    title = "Access Genius Insights"
}) {
    const { instance } = useMsal();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const ref = useRef();

    // Toggles collapsed state on click
    const handleClick = useCallback(() => setExpanded(x => !x), []);
    const stopPropagation = useCallback(e => e.stopPropagation(), []);

    // Handles collapsing when clicking outside of the expanded navbar
    useEffect(() => {
        const handleClickOutside = (event) => {
            // If we are clicking on the nav, return (to prevent collapsing)
            if (ref.current.contains(event.target)) return;
            setExpanded(false);
        };

        document.body.addEventListener('click', handleClickOutside, { capture: true });

        // Cleanup
        return () => document.body.removeEventListener('click', handleClickOutside, { capture: true });

    }, []);

    function displayUserName(account) {
        const firstName = account?.firstName || account?.idTokenClaims?.given_name ;
        const lastName = account?.lastName || account?.idTokenClaims?.family_name;
        const email = (account?.email || account?.idTokenClaims?.emails[0] || "").toLowerCase();
        const fallback = 'User';

        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        } else if (email) {
            return email;
        } else {
            return fallback;
        }
    }

    /*
        When collapsed, shows icon and right arrow. Small width.
        When opened, shows word and icon, and left arrow. 
    */
    return (
        <SideBarNav ref={ref} $expanded={expanded} onClick={handleClick} >
            <ExpandIcon $expanded={expanded}>
                <img src={theme.icons.expand} alt="Arrow" />
            </ExpandIcon>
            <Header $expanded={expanded}>
                <Logo $expanded={true} src={theme.icons.sidebarLogo} alt="Logo" />
                <h1>{title}</h1>
                <span>{displayUserName(account)}</span>
            </Header>

            <ListContainer $expanded={expanded}>
                <ul>
                    {mainLinks.map(({ path, menuItem, icon }, index) => (
                        <li key={index} onClick={stopPropagation}>
                            <InternalLink to={path} activeClassName='active'>
                                {icon && <img width="24" src={icon} alt={menuItem}></img>}
                                <ShowOnExpand as='span' $expanded={expanded}>{menuItem}</ShowOnExpand>
                            </InternalLink>
                        </li>
                    ))}
                    <li>
                        <HorizontalLine $expanded={expanded} />
                    </li>
                    {secondaryLinks.map(({ path, menuItem, icon, isExternal, tooltip }, index) => (
                        isExternal ?
                            <li key={index} onClick={stopPropagation}>
                                <ExternalLink href={path} target="_blank">
                                    {icon && <img src={icon} alt='Globe Icon' />}
                                    <ShowOnExpand as='span' title={tooltip} $expanded={expanded}>{menuItem}</ShowOnExpand>
                                </ExternalLink>
                            </li> :
                            <ShowOnExpand as='li' key={index} onClick={stopPropagation} $expanded={expanded}>
                                <InternalLink to={path} activeClassName='selected'>
                                    {icon && <img src={icon} alt='Globe Icon' />}
                                    <span>{menuItem}</span>
                                </InternalLink>
                            </ShowOnExpand>
                    ))}
                </ul>
            </ListContainer>

            <LogoutButton $expanded={expanded} onClick={e => e.stopPropagation() || instance.logoutRedirect(logoutRequest)}>
                <span>LOG OUT</span>
            </LogoutButton>
        </SideBarNav>
    );
}

export default SideBar;
