import { apiGet } from "./AGIAPI";

export async function getBoundingBox(kind, id) {
    return await apiGet(`bbox/${encodeURIComponent(kind)}/${encodeURIComponent(id)}`);
}

export async function getBoundingBox2(id) {
    return await apiGet(`bbox2/${encodeURIComponent(id)}`);
}

