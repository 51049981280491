import React from 'react';
import { useContext, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AppContext from "./AppContext";
import Page from "./components/Page";
import { track } from "./api/user";
import { createPageViewEvent } from './util/events';

export default function AppPages({ pages, isLoading }) {
    const location = useLocation();
    const { configuration, user } = useContext(AppContext);

    let dates = {};
    let dbColor = {};

    if (configuration) {
        //debugger;
        if(configuration.dates){
            dates = configuration.dates;
        }
        dbColor = configuration.AGICurrentDBColor;
    }

    const activePage = pages.find(p => p.path === location.pathname);

    // Create tracking event when the page changes
    useEffect(() => {
        if (!user)
            return;

        const event = createPageViewEvent(location.pathname, user);
        track(event);
    }, [user, location]);

    return (
        <Page title={activePage?.title} isLoading={isLoading} dates={dates} dbColor={dbColor}>
            <Switch>
                {pages.map(({ path, component }, i) => (
                    <Route key={i} path={path} component={configuration && component} />
                ))}
                <Route>
                    <Redirect to={pages[0].path} />
                </Route>
            </Switch>
        </Page>
    );
}

