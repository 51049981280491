export function getTemplate(stringTemplates, id, productId = null) {
    const entry = Object.entries(stringTemplates)
        .find(([k,]) => k.toUpperCase() === id.toUpperCase());

    if (!entry)
        return "";

    const [, productMappings] = entry;

    for (const obj of productMappings) {
        if (obj.productId === productId)
            return obj.template;
    }

    for (const obj of productMappings) {
        if (obj.productId === null)
            return obj.template;
    }

    return "";
}