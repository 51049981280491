import  PayerLandscapeReportContainer  from '../components/PayerLandscapeReportContainer';
import { reports } from '../reports';
import { useTheme } from "styled-components";

function PayerLandscape() {
	const theme = useTheme();
	return (
		<PayerLandscapeReportContainer
			payerLandscapeReportId={reports.payerLandscape.id}
			topSubReportId={reports.payerLandscapeTopHcps.id}
			topSubReportTitle={theme.titles.payerLandscapeTopHcps}></PayerLandscapeReportContainer>
	);
}

export default PayerLandscape;
