import React from 'react';
import styled, { useTheme } from "styled-components";

const Button = styled.button`
    color: white;
    font-weight: bold;
    border: none;
    padding: .2rem .2rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary};

    &:hover {
        filter: brightness(85%);
    }

    & :first-child {
        margin-right: .5rem;
    }
`;

function ViewMoreButton({ ...props }) {
    const theme = useTheme();
    return (
        <Button {...props}>
            <img src={theme.icons.viewMore} alt='View More' />
            <span>View More</span>
        </Button>
    );
}

export default ViewMoreButton;
