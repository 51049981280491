import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Report, useSubreport } from './Report';

const TopHcpsHeader = styled.div`
    align-self: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    &:last-child {
        flex: auto;
    }
`;

const StatLabel = styled.span`
    color: #7B7B7B;
`;

const Column = styled.div`
    flex: auto;
`;

const InternalReferenceDisclaimer = styled.div`
    color: ${props => props.theme.colors.disclaimer};
    font-weight: bold;
    text-align: end;
    margin: .5rem;
`;

function PayerInfo({
    parentReport,
    payerInfo,
    mainGeography,
    reportId,
    title
}) {
    const geoLevel = payerInfo.GEOLEVEL;
    const geographyName = mainGeography?.name;
    const geoStringsAreEqual = geoLevel.toLowerCase().trim() === geographyName.toLowerCase().trim();

    // TODO hardcoded column PlanID on 'payer_landscape'
    const planID = payerInfo.PLANID;

    // TODO hardcoded column BenType on 'payer_landscape'
    const benType = payerInfo.BENTYPE;
    const payerInfoFilter = useMemo(() => ({
        // TODO hardcoded column PlanID on 'payer_landscape_top_hcps'
        PlanID: planID,
        // TODO hardcoded column BenType on 'payer_landscape_top_hcps'
        BenType: benType
    }), [planID, benType]);

    const topHcpsReport = useSubreport({
        reportId: reportId,
        parentReport: parentReport,
        filters: payerInfoFilter
    });

    return (
        <>
            <TopHcpsHeader>
                {/* TODO Hardcoded column name PayerDisplayName */}
                <h1>{title} {payerInfo.PAYERDISPLAYNAME}</h1>
            </TopHcpsHeader>
            <Row>
                <Column>
                    {/* TODO Hardcoded column names TimePeriod and GeoLevel*/}
                    <h2><StatLabel>TIME PERIOD: </StatLabel>{payerInfo.TIMEPERIOD}</h2>
                        { 
                            geoStringsAreEqual ? 
                                <h2>{geoLevel}</h2> : 
                                <h2><StatLabel>{geoLevel}: </StatLabel>{geographyName}</h2> 
                        }    
                    
                </Column>

                <Column>
                    <InternalReferenceDisclaimer>
                        FOR INTERNAL REFERENCE ONLY - DO NOT DETAIL.
                    </InternalReferenceDisclaimer>
                </Column>
            </Row>
            <Row>
                <Report report={topHcpsReport} isModal={true} />
            </Row>
        </>
    );

}

export default PayerInfo;
