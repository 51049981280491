import React, { Fragment, useEffect, useState, useContext } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import DropdownFilter from '../components/DropdownFilter';
import { useDeepCompareWithRef } from '../util/useDeepCompareWithRef';
import usePrevious from '../util/usePrevious';
import { SelectedFiltersContext } from '../util/SelectedFiltersContext';
import { IoCloseCircleOutline } from 'react-icons/io5';
import DynamicFilter from './DynamicFilter';
import getDefaultGeoOption from '../util/getDefaultGeoOption';

const DropdownContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -17px;

    label {
        font-weight: bold;
        margin: 10px;
        color: #7B7B7B!important;
        min-width: 19.125rem;
    }

    span,
    select {
        margin-left: .5rem;
    }

    span {
        color: gray;
    }

    select {
        font-weight: bold;
    }

    // Gradient
    background: transparent linear-gradient(180deg, #F4F4F4 0%, #FDFDFD 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #0000000D;
    opacity: 1;
    padding: 0 0 0 1rem;
    margin: 0;
    width: 100%;

    input[type=checkbox] {
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        padding: 10px;
    }
`;

const CloseContainer = styled.div`
    font-weight: bold;
    margin: 6px 0 6px 3px;
    color: #7B7B7B;
    min-width: 19.125rem;
    flex-basis: 100%;
    transform: translateY(-10px);
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;

    & span {
        color: ${props => props.$filtersactive ? '#484848;' : '#a6a6a6;'}
    }

    & :hover {
      cursor: ${props => props.$filtersactive ? 'pointer;' : 'not-allowed;'}
    }
`;

const CloseIcon = styled(IoCloseCircleOutline)`
    font-size: xx-large;
    color: ${props => props.$filtersactive ? '#484848;' : '#a6a6a6;'}
    transform: translate(-3px, 10px);
    width: 27px;
    
`;

// Custom Options component to remove mouseover for large lists which causes substantial slowdown
// https://github.com/JedWatson/react-select/issues/3128

function noop() { }

function CheckboxOption(props) {
    delete props.innerProps.onMouseMove; //FIX LAG!!
    delete props.innerProps.onMouseOver;  //FIX LAG!!

    return (
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={noop}
            />
            {" "}
            <label>{props.label}</label>
        </components.Option>
    );
};

function findOption(options, value) {
    if (!options || options.length === 0)
        return null;
    return options.find(opt => opt.value === value) || options[0];
}

function ReportFilterGroup({
    filters,
    filterState,
    setFilterState,
    setFilterOptions,
    optionState,
    setOptionState,
    showColumns,
    setShowColumns,
    mainGeography,
    setMainGeography,
    geoLevelOptions,
    columns,
    children,
    setSelectedCompetitors,
    skipNationalAsDefaultGeography
}) {
    const productId = filterState.ProductID;
    const [filtersActive, setFiltersActive] = useState();

    const filterWithoutPayerDisplayName = useDeepCompareWithRef(filterState, 'PayerDisplayName');

    const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

    const optionsByProductFilter = (option) => {
        /** EMPTY appliesToProductId array means ALL products have this option: */
        if (!option?.appliesToProducts || !option?.appliesToProducts?.length) return true;

        /** appliesToProducts array containing IDs - only include this option if current product ID in array: */
        return option?.appliesToProducts?.includes(productId);
    };

    const geographyOptionsFilter = (option) => {
        /** EMPTY appliesToProductId array means ALL products have this option: */
        if (!option?.appliesToProductId?.length) return true;

        /** appliesToProductId array containing IDs - only include this option if current productId in array: */
        return option?.appliesToProductId?.includes(productId);
    };

    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (isEmpty(selectedFilters)) {
            setFiltersActive(false);
        } else {
            setFiltersActive(true)
        }

    }, [selectedFilters])

    // Reset geography when switching products
    const previousProductId = usePrevious(productId);
    const geographyKind = mainGeography.kind;
    useEffect(() => {
        if (productId === previousProductId){
            return;
        }
        let newGeography = { kind: geographyKind, id: null, name: 'National' };
        if(skipNationalAsDefaultGeography){
            const newGeographyOption = geoLevelOptions?.find(geo => geo.value === geographyKind)?.options.find(opt => opt.value !== null);
            if(newGeographyOption){
                newGeography = { kind: geographyKind, id: newGeographyOption.value, name: newGeographyOption.label };
            }            
        }
        setMainGeography(newGeography);
    }, [productId, previousProductId, geographyKind, setMainGeography, geoLevelOptions, skipNationalAsDefaultGeography]);

    const renderFilter = ({ id, label, filterKind, ...props }, i) => {
        const { colId } = props;

        switch (filterKind.toLowerCase()) {
            case 'dropdown': {
                const value = filterState[id];

                const setValue = v => {
                    setFilterState({
                        [id]: v
                    });
                };

                const { options } = props;
                const filteredOptions = options.filter(optionsByProductFilter);
                const selectedOption = findOption(filteredOptions, value);
                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DropdownFilter
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            value={selectedOption}
                            onChange={option => setValue(option.value)}
                            options={filteredOptions}
                            currentStateValue={value} />
                    </label>
                );
            }
            case 'multiselect': {
                const value = filterState[id];
                const setValue = v => {
                    setFilterState({
                        [id]: v
                    });
                };

                const setOptions = v => {
                    setFilterOptions(v)
                }

                let { options } = props;


                const selectedOptions = (value && value.map(v => options.find(opt => opt.value === v)).filter(x => x !== undefined)) || [];
                // If our selected options don't line up with the current value, set the new selected value
                // this happens if the new list list of available options changes to not include items from our previous selection
                if (value && value.length && selectedOptions.length !== value.length) {
                    setFilterState({
                        [id]: selectedOptions.map(opt => opt.value)
                    });
                }

                function isDisabled() {
                    if (id.toLowerCase() === 'prescribername') {
                        return false;
                    }

                    if (options.length === 0) {
                        return true;
                    }

                    return false;
                }

                function isHcpName() {
                    if (id.toLowerCase() === 'prescribername') {
                        return true;
                    }

                    return false;
                }

                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DropdownFilter
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            options={options}
                            onChange={options => setValue(options.map(opt => opt.value))}
                            setOptions={setOptions}
                            value={selectedOptions}
                            components={{ Option: CheckboxOption }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={isDisabled()}
                            isHcpName={isHcpName()}
                            filterState={filterState}
                            isMulti />
                    </label>
                );
            }
            case 'show-hide-columns': {
                const value = showColumns[id];
                const setValue = v => {
                    setShowColumns({
                        ...showColumns,
                        [id]: v
                    });
                };

                const { options } = props;
                const filteredOptions = options.filter(optionsByProductFilter);
                const selectedOptions = (value && filteredOptions.filter(opt => !value.includes(opt.value))) || [];

                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DropdownFilter
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            options={filteredOptions}
                            onChange={newValues => {
                                const newSelection = filteredOptions
                                    .filter(opt => !newValues.includes(opt))
                                    .map(opt => opt.value);
                                setValue(newSelection);
                                setSelectedCompetitors(newValues.map(opt => opt.value));
                            }}
                            value={selectedOptions}
                            components={{ Option: CheckboxOption }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti />
                    </label>
                );
            }
            case 'geography-type': {
                const geoLevel = (mainGeography && mainGeography.kind) || null;
                const { geographyId, geographyLabel } = props;
                const selectedGeolevel = findOption(geoLevelOptions, geoLevel);
                if (!selectedGeolevel) {
                    console.error('Cannot find geolevel', geoLevel);
                    return <Fragment key={i}></Fragment>;
                }

                
                const setSelectedGeolevel = v => {
                    const newGeolevel = findOption(geoLevelOptions, v);
                    const newGeography = getDefaultGeoOption(newGeolevel, skipNationalAsDefaultGeography);

                    if (!newGeography) {
                        setMainGeography({
                            kind: newGeolevel.value,
                            id: null,
                            name: newGeolevel.label
                        });
                        return;
                    }

                    setMainGeography({
                        kind: newGeolevel.value,
                        id: newGeography.value !== null ? newGeography.value : null,
                        name: newGeography.label
                    });
                };


                const { options: geographyOptions } = selectedGeolevel;
                const selectedGeography = findOption(geographyOptions, mainGeography.id);
                const defaultGeography = getDefaultGeoOption(selectedGeolevel, skipNationalAsDefaultGeography);
                const setSelectedGeography = v => {
                    const label = findOption(geographyOptions, v).label;
                    setMainGeography({
                        kind: geoLevel,
                        id: v,
                        name: label
                    });
                };

                return (
                    <Fragment key={i}>
                        <label>
                            <div>{label}</div>
                            <DropdownFilter
                                id={id}
                                label={label}
                                filterKind={filterKind.toLowerCase()}
                                options={geoLevelOptions.map(({ label, value }) => ({ label, value }))}
                                onChange={option => setSelectedGeolevel(option.value)}
                                value={selectedGeolevel} />
                        </label>
                        <label>
                            <div>{geographyLabel}</div>
                            <DropdownFilter
                                id={geographyId}
                                label={geographyLabel}
                                options={geographyOptions?.filter((option) => geographyOptionsFilter(option))}
                                onChange={v => setSelectedGeography(v.value)}
                                value={selectedGeography} 
                                defaultOption={defaultGeography}/>
                        </label>
                    </Fragment>
                );
            }
            case 'option': {
                const { optionId, options } = props;
                const value = optionState[optionId];
                const setValue = v => {
                    setOptionState({
                        ...optionState,
                        [optionId]: v
                    });
                };
                const filteredOptions = options.filter(optionsByProductFilter);
                const selectedOption = findOption(filteredOptions, value);

                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DropdownFilter
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            id={id}
                            options={filteredOptions}
                            onChange={v => setValue(v.value)}
                            value={selectedOption}
                            currentStateValue={value} />
                    </label>
                );
            }
            case 'multi-suggest': {
                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DynamicFilter
                            id={id}
                            colId={colId}
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            components={{ Option: CheckboxOption }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isHcpName={false}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            setFilterOptions={setFilterOptions}
                            filterWithoutPayerDisplayName={filterWithoutPayerDisplayName}
                            isMulti />
                    </label>
                );
            }
            case 'text-suggest': {
                return (
                    <label key={i}>
                        <div>{label}</div>
                        <DynamicFilter
                            id={id}
                            colId={colId}
                            label={label}
                            filterKind={filterKind.toLowerCase()}
                            components={{ Option: CheckboxOption }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={false}
                            isHcpName={true}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            filterWithoutPayerDisplayName={filterWithoutPayerDisplayName}
                            isMulti />
                    </label>
                );
            }

            default:
                console.warn(`Unknown filter kind ${filterKind}`);
                return (<Fragment key={i}></Fragment>);
        }
    };

    function resetFilters() {
		setSelectedFilters({});
		//Ensure the main geography is reset to the default value
		const newGeography = getDefaultGeoOption(geoLevelOptions[0], skipNationalAsDefaultGeography);
		setMainGeography({
			kind: geoLevelOptions[0].value,
			id: newGeography?.value !== null ? newGeography.value : null,
			name: newGeography?.label,
		});
	}

    return (
        <DropdownContainer>
            {filters.map(renderFilter)}

            <CloseContainer $filtersactive={filtersActive}>
                <span onClick={() => filtersActive ? resetFilters() : null}><CloseIcon $filtersactive={filtersActive} />RESET FILTERS</span>
            </CloseContainer>

        </DropdownContainer>
    );
}

export default ReportFilterGroup;
