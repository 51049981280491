import React, { useCallback, useContext, useEffect, useState } from 'react';
import { deleteUser, getUsers } from '../api/user';

import TableComponent from '../components/TableComponent';
import AppContext from '../AppContext';
import styled from 'styled-components';


const AdminTableContainer = styled.div`
    overflow: auto;
    flex: 1 1;
    flex-basis: 0;
`;

const DeleteButton = styled.button`
    color: white;
    border: none;
    font-size: inherit;
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary};
    font-weight: bold;
    padding: .4rem .5rem;
    width: 6rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
`;

const columns = [
    {
        Header: 'FIRST NAME',
        accessor: 'firstName',
    },
    {
        Header: 'LAST NAME',
        accessor: 'lastName',
    },
    {
        Header: 'EMAIL',
        accessor: 'email'
    },
    {
        Header: 'ROLE',
        accessor: 'role'
    },
    {
        Header: ' ',
        Cell: function ({ row, onDeleteUser }) {
            return (
                <DeleteButton onClick={() => onDeleteUser(row.original)}>
                    Delete
                </DeleteButton>
            );
        }
    }
];

function AdminPanel() {
    const { startOp } = useContext(AppContext);
    const [users, setUsers] = useState([]);
    const [needsRefresh, setNeedsRefresh] = useState(true);

    useEffect(() => {
        if (!needsRefresh)
            return;

        let unmounted = false;

        startOp(async () => {
            try {
                const users = await getUsers();
                if (unmounted)
                    return;
                setUsers(users);
            }
            finally {
                setNeedsRefresh(false);
            }
        });

        return () => unmounted = true;
    }, [startOp, needsRefresh]);

    const onDeleteUser = useCallback(user => {
        startOp(async () => {
            try {
                await deleteUser(user);
            }
            finally {
                setNeedsRefresh(true);
            }
        });
    }, [startOp]);

    return (
        <>
            <AdminTableContainer>
                <TableComponent columns={columns} data={users} cellProps={{ onDeleteUser }} />
            </AdminTableContainer>
        </>
    );
}

export default AdminPanel;
