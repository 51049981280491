import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import App from './App';
import { msalInstance } from '.';
import { theme as defaultTheme } from './theme';

// Light wrapper around msal and auth settings for the AGI App
export default function MsalApp({
    theme = defaultTheme
}) {
    return (
        <MsalProvider instance={msalInstance}>
            <App theme={theme} />
        </MsalProvider>
    );
}
