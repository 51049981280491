import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { Report, useReport } from "../components/Report";
import { init, reducer, SET_MAIN_GEOGRAPHY } from '../reducers/geography.reducer';
import AppContext from "../AppContext";
import { geoFilters } from '../util/geoFilters';
import { reports } from '../reports';

function PayerTopHCPs() {

    const { configuration } = useContext(AppContext);

    const [state, dispatch] = useReducer(reducer, configuration, init);

    const geographySelection = state.geographySelection;

    const setMainGeography = useCallback(value => dispatch({
        type: SET_MAIN_GEOGRAPHY,
        value: value
    }), [dispatch]);

    const { mainGeography, history } = geographySelection;
    const { geoLevelOptions, skipNationalAsDefaultGeography } = configuration || {};

    // Set the special geography up/down filtering
    const geoLevelFilters = useMemo(() => geoFilters(history, mainGeography, false),
        [history, mainGeography]);

    const report = useReport({ reportId: reports.payerTopHcps.id, filters: geoLevelFilters }); // setFilterOptions is in here
    if (!configuration)
        return null;

    return (
        <Report
            report={report}  // setFilterOptions is in here
            mainGeography={mainGeography}
            setMainGeography={setMainGeography}
            geoLevelOptions={geoLevelOptions}
            skipNationalAsDefaultGeography={skipNationalAsDefaultGeography} />
    );
};

export default PayerTopHCPs;
