import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled, { css, useTheme } from 'styled-components';

import Legend from './Legend';

import { percentString } from '../util/formatting';
import { Report, useSubreport } from './Report';
import { queryReport } from '../api/report';


const CustomTabs = styled(Tabs)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: -4rem;

	> * {
		flex: auto;
	}
`;

const PrescriberInfoHeader = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	padding: 0.3rem 1rem;
	margin-bottom: 0.5rem;
	color: white;
	display: flex;
	justify-content: space-between;
	& :first-child {
		font-weight: 400;
	}
	& :last-child {
		font-weight: 200;
	}
`;

const InternalReferenceDisclaimer = styled.div`
	color: ${(props) => props.theme.colors.disclaimer};
	font-weight: bold;
	text-align: end;
	margin: 0.5rem;
`;

const ChartLegendContainer = styled.div`
	display: flex;

	justify-content: center;
	margin-left: 25%;

	@media screen and (max-width: 820px) {
		margin-left: 15%;
	}
`;

const ChartContainer = styled.div`
	flex: 0 0 10rem;
`;

const CustomTab = styled(Tab)`
	border: 0;
	font-weight: bolder;
	padding: 8px 25px 0 0;
	cursor: pointer;

	${(props) => {
		if (props.selected) {
			return css`
				border-top: 6px solid black;
				color: black;
				transition: 1s;
			`;
		}

		return css`
			border-top: 6px solid #d3d3d3;
			color: #d3d3d3;
		`;
	}}
`;

const CustomTabPanel = styled(TabPanel)`
	${(props) =>
		props.selected &&
		css`
			flex: auto;
			display: flex;
			flex-direction: column;
		`}
	h2 {
		overflow-wrap: break-word;
		width: 60%;
	}
`;

const CustomTabList = styled(TabList)`
	margin: 0;
	padding: 0;
	border: none;
	display: flex;
	list-style: none;
`;

function PrescriberInfo({ parentReport, prescriberInfo, payerMixReportId, hcpMessagesReportId, top5PlansReportId, entityAlias, hcpType, podGeneration }) {
	const theme = useTheme();
	const [messageEffectiveCount, setMessageEffectiveCount] = useState(0);
	const { prescriberId, messageStats, messageCount } = prescriberInfo;
	
	const payerMixFilter = useMemo(
		() => ({
			// TODO hardcoded column DisplayPrescriberID in 'hcp_payer_mix'
			DisplayPrescriberID: prescriberId,
		}),
		[prescriberId]
	);

	const hcpPayerMixReport = useSubreport({
		reportId: payerMixReportId,
		parentReport: parentReport,
		filters: payerMixFilter,
	});

	const hcpMessagesFilter = useMemo(
		() => ({
			// TODO hardcoded column PrescriberID in 'hcp_messages'
			PrescriberID: prescriberId,
			HcpType: hcpType
		}),
		[prescriberId, hcpType]
	);

	useEffect(() => {
		
		const queryMessages = async () => {
			try{
				const { results } = await queryReport(hcpMessagesReportId, {
					filters: {
						...hcpMessagesFilter,
						...(parentReport.filters || {})
					},
				});
				setMessageEffectiveCount(results.length);
			}catch(e){
				console.log("Could not retrieve messages for prescriber " + prescriberId + " due to error: " + e);
			}
		};

		// For pod Gen 2, we query the messages to obtain the count
		if(podGeneration === 2){
			queryMessages();
		}else{
			setMessageEffectiveCount(messageCount);
		}
	}, [hcpMessagesFilter, hcpMessagesReportId, parentReport.filters, messageCount, prescriberId, podGeneration]);

	const hcpMessagesReport = useSubreport({
		reportId: hcpMessagesReportId,
		parentReport: parentReport,
		filters: hcpMessagesFilter,
	});

	const [selectedMessageCode, setSelectedMessageCode] = useState(null);

	const messageCodeFilter = useMemo(
		() => ({
			// TODO Hardcoded column 'MessageCode' in 'hcp_top_5'
			MessageCode: selectedMessageCode,
			HcpType: hcpType
		}),
		[selectedMessageCode, hcpType]
	);

	const hcpTop5Report = useSubreport({
		reportId: top5PlansReportId,
		parentReport: hcpMessagesReport,
		filters: messageCodeFilter,
	});

	const pieData = useMemo(
		() =>
			messageStats
				? messageStats.map(({ label, value, color }, i) => ({
						title: label,
						value: value * 100,
						color: color ? color : theme.palette[i % theme.palette.length],
				  }))
				: [],
		[messageStats, theme]
	);

	const legendItems = useMemo(
		() =>
			messageStats
				? messageStats.map(({ label, value, color }, i) => ({
						label: label,
						subLabel: percentString(value),
						color,
				  }))
				: [],
		[messageStats]
	);

	// Set selected message code automatically on load
	const messageTypesData = hcpMessagesReport.data;
	useEffect(() => {
		if (!messageTypesData || messageTypesData.length === 0) {
			setSelectedMessageCode(null);
			return;
		}

		// Already have one selected
		if (selectedMessageCode) {
            return;
        }

		// TODO hardcoded column 'MessageCode' on 'hcp_messages'
		setSelectedMessageCode(messageTypesData[0].MESSAGECODE);
	}, [messageTypesData, selectedMessageCode]);

	// TODO hardcoded column 'MessageCode' on 'hcp_messages'
	const onRowClick = useCallback((r) => setSelectedMessageCode(r.MESSAGECODE), [setSelectedMessageCode]);

	return (
		<CustomTabs>
			<Row>
				<div>
					<PrescriberInfoHeader>
						<span>{prescriberInfo && prescriberInfo.hcp}</span>
						<span>
							{theme.viewMoreHeaderText}: {prescriberInfo && percentString(prescriberInfo.access)}
						</span>
					</PrescriberInfoHeader>
					<CustomTabList>
						<CustomTab>{entityAlias} Info</CustomTab>
						{ messageEffectiveCount > 0 ? <CustomTab>{entityAlias} Messages</CustomTab> : null}
					</CustomTabList>
				</div>

				<div>
					<InternalReferenceDisclaimer>FOR INTERNAL REFERENCE ONLY - DO NOT DETAIL.</InternalReferenceDisclaimer>

					<ChartLegendContainer>
						<ChartContainer>
							<PieChart
								segmentsStyle={{
									borderWidth: 5,
								}}
								data={pieData}
								lineWidth={50}
								animate={true}
							/>
						</ChartContainer>
						<Legend legendItems={legendItems} />
					</ChartLegendContainer>
				</div>
			</Row>
			<CustomTabPanel>
				<h2>{entityAlias} Payer Mix</h2>
				<Report report={hcpPayerMixReport} isModal={true} />
			</CustomTabPanel>

			{messageEffectiveCount > 0 && (
				<CustomTabPanel>
					<h2>{theme.viewHCPMessageText}</h2>
					<Report report={hcpMessagesReport} onRowClick={onRowClick} isModal={true} selectedMessageCode={selectedMessageCode} />
					<h2>{theme.viewTop5planText}</h2>
					<Report report={hcpTop5Report} disableQuery={!hcpTop5Report.filters?.MessageCode} isModal={true} />
				</CustomTabPanel>
			)}
		</CustomTabs>
	);
}

export default PrescriberInfo;
