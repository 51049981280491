import RepMessagesReportContainer from '../components/RepMessagesReportContainer';
import { reports } from '../reports';
import { useTheme } from "styled-components";

function RepMessagesGroupPractice() {
	const theme = useTheme();
	return (
		<RepMessagesReportContainer
			reportId={reports.repMessagesGroupPractice.id}
			hcpInfoReportId={reports.groupPracticeInfo.id}
			payerMixReportId={reports.groupPracticePayerMix.id}			
			entityAlias={theme.aliases.groupPractice}
			hcpType={"Prescriber Group Practice"}
		/>
	);
}

export default RepMessagesGroupPractice;
