import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Report, useSubreport } from './Report';

const TopHcpsHeader = styled.div`
    align-self: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    &:last-child {
        flex: auto;
    }
`;

const StatLabel = styled.span`
    color: #7B7B7B;
`;

const Column = styled.div`
    flex: auto;
`;

const InternalReferenceDisclaimer = styled.div`
    color: ${props => props.theme.colors.disclaimer};
    font-weight: bold;
    text-align: end;
    margin: .5rem;
`;

function interpolateReportHeader(report, varName, toReplace){    
    return report.replace(varName, toReplace);
}

function configureReportHeader(formularyInfo){
    let reportTemplate = formularyInfo.stringTemplateId;
    const planID = '{{PlanId}}';
    const planName = '{{PlanName}}';
    const benType = '{{Bentype}}';
    const payerDisplayName = '{{PayerDisplayName}}';
    const row = formularyInfo.row;
    reportTemplate = interpolateReportHeader(reportTemplate, planID, row.PLANID.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, planName, row.PAYERDISPLAYNAME.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, benType, row.BENTYPE.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, payerDisplayName, row.PAYERDISPLAYNAME.toString());


    return reportTemplate;
}

function FormularyDrilldown({
    parentReport,
    formularyInfo,
}) {
    const formularyReportInfo = useSubreport({
        reportId: formularyInfo.popupReportId,
        fields: formularyInfo.fields,
        parentReport: parentReport,
        filters: {
            /* TODO Hardcoded column name PAYERLANDSCAPEIDENTITY */
            PAYERLANDSCAPEIDENTITY: formularyInfo.row.PAYERLANDSCAPEIDENTITY
        }
    });

    return (
        <>
            <TopHcpsHeader>
                <h1>{configureReportHeader(formularyInfo)}</h1>
                </TopHcpsHeader>

            <Row>
                <Report isParent={false} report={formularyReportInfo} displayFilter={false} />
            </Row>
        </>
    );

}

export default FormularyDrilldown;
