import equal from 'fast-deep-equal';
import React, { useEffect, useState, useReducer, useCallback, useMemo, useRef, Fragment, useContext } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import Modal from '../components/Modal';
import FormularyDrilldown from '../components/FormularyDrilldown';
import { useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import styled, { css, useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import ReportFilterGroup from './ReportFilterGroup';

import { getReportConfiguration, queryReport } from '../api/report';
import isSelectionActive from '../util/isSelectionActive';
import { numberString, percentString } from '../util/formatting';
import { getExport } from '../api/export';
import AppContext from '../AppContext';
import '../styles/tooltip.css';
import { reports} from '../reports';

const TotalRecords = styled.div`
    position: absolute;
    bottom: 40px;
    right: 12px;
    font-size: 14px;
    font-weight: normal;
`;

const TopHcpsHeader = styled.div`
    align-self: center;
`;

const ImageLink = styled.a`
    padding-left: 0.4rem;
    filter: invert(.5);
    &:hover {
        filter: invert(.5) sepia(1) saturate(5) hue-rotate(175deg);
    }
`;

const InfoLink = styled.div`
    cursor: pointer;
    float: right;
    margin-right: 5px;
`

const CustomReport = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    
    ${({ isModal }) => isModal && `
        flex-basis: 0;
    `}
`;

// hide vertical scroll (width), show horizontal scroll (height)
const ScrollContainer = styled.div`
    &::-webkit-scrollbar { 
        height: 10px;
        width: 0px;
        border: 1px solid #fff; 
    }
    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #eeeeee;
      }
    &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
    }
`;

const CustomTable = styled.table.attrs(props => ({
    $loading: false,
    ...props
}))`
    isolation: isolate;
    border-collapse: collapse;
    width: 100%;
    font-weight: bolder;
    color: gray;
    cursor: ${props => props.$loading ? 'progress' : 'auto'};
    thead {
        position: sticky;
        top: 0px;
        background: white;
        z-index: 500;
        tr {
            color: gray;
        }
        th[role='columnheader'] {
            white-space: nowrap;
            text-align: start;
            padding: 2rem .5rem 1rem .5rem;
            position: relative;            
        }
        th {
            white-space: nowrap;
            padding: 2rem 0 1rem 0;
            position: relative;
        }
    }
    tbody {
        background-color: #F4F4F4;
        z-index: 200;
        transition: opacity 0.5s;
        opacity: ${props => props.$loading ? 0.5 : 1.0};

        td[role='cell'] {
            padding: .5rem;
            text-align: start;
        }
    }
`;

const TableHeader = styled.th`
    cursor: ${props => props.hasTooltip ? 'pointer' : 'auto'};
    text-decoration: ${props => !props.hasTooltip ? 'none' : props.selected ? 'underline dashed #3babff' : 'underline dashed'}; 
    text-underline-offset: 0.5rem;
`;

const TableRow = styled.tr.attrs(props => ({
    $selected: false,
    $isWin: false,
    ...props
}))`
    ${props => props.onClick && css`
        cursor: pointer;
        &:hover {
            background-color: #00000029;
        }
    `}

    ${props => props.$selected ? 'background-color: #00000029;' : ''}
    ${props => props.theme.isWinEnabled && props.$isWin ? `color: ${props.theme.colors.isWin};` : ''}
`;

const ExcelIconImg = styled.img.attrs(props => ({
    $downloadable: false,
    ...props
}))`
    padding-right: .5rem;
    cursor: pointer;
    filter: ${props => props.$downloadable ? 'invert(16%) sepia(24%) saturate(1986%) hue-rotate(60deg) brightness(83%) contrast(82%);  /* excel green */' : 'filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(154deg) brightness(99%) contrast(89%); /* gray */'}
    &:hover {
        filter: ${props => props.$downloadable ? props.theme.colors.excelColorFilter : ''};
    }
`;

const LoadMoreButton = styled(FaChevronDown)`
    display: block;
    margin: 0rem auto;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    &:hover {
        transition: .3s;
        transform: scale(1.3);
    }
`;

const BarsContainer = styled.div.attrs(props => ({ $sortDescending: null, ...props }))`
    cursor:pointer;
    width: 40px;
    div {
        width: 33px;
        height: 3px;
        margin: 6px 0px;
        position: relative;
        background: gray;
        border-radius: 1rem;
        transition: transform .2s ease-in-out;
        transform-origin: left center;
        z-index: 1;
    }

    ${props => {
        if (props.$sortDescending === null)
            return;

        if (!props.$sortDescending)
            return css`
                div:nth-child(1) {
                    transform: scaleX(0.33);
                }

                div:nth-child(2) {
                    transform: scaleX(0.66);
                }
            `;

        return css`
            div:nth-child(2) {
                transform: scaleX(0.66);
            }

            div:nth-child(3) {
                transform: scaleX(0.33);
            }
        `;
    }}

    ${props => {
        const isNumeric = props.$type.toLowerCase() === 'number' || props.$type.toLowerCase() === 'percent';

        if (props.$sortDescending && !isNumeric)
            return css`
                &:hover {
                    div {
                        transform: unset;
                    }
                }
            `;

        if (props.$sortDescending === null && !isNumeric)
            return css`
                &:hover {
                    div:nth-child(1) {
                        transform: scaleX(0.33);
                    }

                    div:nth-child(2) {
                        transform: scaleX(0.66);
                    }
                }
            `;

        if (!props.$sortDescending && !isNumeric)
            return css`
              &:hover {
                  div:nth-child(1) {
                      transform: scaleX(1.00);
                  }
                  
                  div:nth-child(2) {
                      transform: scaleX(0.66);
                  }

                  div:nth-child(3) {
                      transform: scaleX(0.33);
                  }
              }
          `;


        // new behavior for numeric columns
        if (props.$sortDescending && isNumeric)
            return css`
                &:hover {
                    div:nth-child(1) {
                        transform: scaleX(0.33);
                    }

                    div:nth-child(2) {
                        transform: scaleX(0.66);
                    }

                    div:nth-child(3) {
                        transform: scaleX(1.0);
                    }
                }
            `;

        if (props.$sortDescending === null && isNumeric)
            return css`
              &:hover {
                  div:nth-child(1) {
                      transform: scaleX(1.00);
                  }
                  
                  div:nth-child(2) {
                      transform: scaleX(0.66);
                  }

                  div:nth-child(3) {
                      transform: scaleX(0.33);
                  }
              }
            `;

        if (!props.$sortDescending && isNumeric)
            return css`
              &:hover {
                  div {
                      transform: unset;
                  }
              }
            `;




    }}
`;

const externalPODlinkMessage = process.env.REACT_APP_EXTERNAL_POD_LINK_MESSAGE;

const initialReportState = {
    configuration: null, // { filters, columns }
    loading: false,
    error: null,
    presetFilters: {}, // object with id per filter
    presetOptions: {}, // object with id per option
    filters: null, // object with id per filter
    showHideColumns: null, // object with id per showHideColumnId
    options: null, // object with id per option
    // Query we are conducting right now
    activeQuery: null,
    // Last queried params
    query: null,   // { filters, order, continuationToken }
    // Query results
    data: null,
    continuationToken: null,
    formularyModalData: null,
    restrictionsModalData: null
};

function filterOptionsByProduct(productId, options) {
    return options.filter(option => {
        if (productId === null)
            return true;
        const products = option.appliesToProducts;
        return !products || products.length === 0 || products.includes(productId);
    });
}

function filterStateProductId(filterState) {
    return Object.entries(filterState).find(kvp => kvp[0].toUpperCase() === 'PRODUCTID')?.[1];
}

function alignConfigurationFilters(reportFilters, columns) {
    const newFilters = [];
    for (const filter of reportFilters) {
        const { filterKind } = filter;

        if (filterKind !== 'show-hide-columns') {
            newFilters.push(filter);
            continue;
        }

        const { options } = filter;

        const newOptions = [];
        for (const option of options) {
            const colId = option.value;

            const col = columns.find(c => c.id === colId);
            if (!col)
                continue;

            const products = col.appliesToProducts;

            newOptions.push({
                ...option,
                appliesToProducts: products || []
            });
        }

        newFilters.push({
            ...filter,
            options: newOptions
        });
    }

    return newFilters;
}

function alignFilterStateToProduct(filterState, reportFilters) {
    const productId = filterStateProductId(filterState);
    if (productId === null) {
        return filterState;
    }

    const newFilterState = { ...filterState };
    for (const filter of reportFilters) {
        const { id, filterKind } = filter;
        switch (filterKind) {
            case 'dropdown': {
                const filteredOptions = filterOptionsByProduct(productId, filter.options);
                if (filteredOptions.length === 0) {
                    // No longer an option
                    delete newFilterState[id];
                    break;
                }

                const value = newFilterState[id];
                const prevSelectedOption = filter.options.find(opt => opt.value === value);
                const option =
                    // An option in the new set with the same value
                    filteredOptions.find(opt => opt.value === value)
                    // Or an option in the new set with the same label as our old option  
                    || (prevSelectedOption && filteredOptions.find(opt => opt.label === prevSelectedOption.label))
                    // or just the first option from the new set
                    || filteredOptions[0];
                newFilterState[id] = option.value;
                break;
            }
            case 'multiselect': {
                const filteredOptions = filterOptionsByProduct(productId, filter.options);
                if (filteredOptions.length === 0) {
                    // No longer an option
                    delete newFilterState[id];
                    break;
                }

                const values = filterState[id];
                const newValues = [];
                for (const value of values) {

                    const prevSelectedOption = filter.options.find(opt => opt.value === value);

                    const option =
                        // An option in the new set with the same value
                        filteredOptions.find(opt => opt.value === value)
                        // Or an option in the new set with the same label as our old option  
                        || (prevSelectedOption && filteredOptions.find(opt => opt.label === prevSelectedOption.label));

                    if (option) {
                        newValues.push(option.value);
                    }
                }
                newFilterState[id] = newValues;
                break;
            }
            case 'option':
            case 'show-hide-columns':
            case 'geography-type':
            case 'multi-suggest':
            case 'text-suggest':
                break;
            default:
                console.warn('Unhandled filter kind', filterKind);
                break;
        }
    }

    return newFilterState;
}

function initialFilterState(reportFilters) {
    const filterState = {};
    for (const filter of reportFilters) {
        const { id, filterKind } = filter;
        switch (filterKind) {
            case 'dropdown':
                filterState[id] = filter.options[0].value;
                break;
            case 'multiselect':
                filterState[id] = [];
                break;
            case 'option':
            case 'show-hide-columns':
            case 'geography-type':
            case 'multi-suggest':
            case 'text-suggest':
                break;
            default:
                console.warn('Unhandled filter kind', filterKind);
                break;
        }
    }

    // Now, before returning, let's align in case there is a ProductID option
    return alignFilterStateToProduct(filterState, reportFilters);
}


function initialShowHideColumnsState(reportFilters) {
    const showHideColumnsState = {};
    for (const filter of reportFilters) {
        const { id, filterKind } = filter;

        switch (filterKind) {
            case 'show-hide-columns': {
                const { options } = filter;
                showHideColumnsState[id] = options.map(opt => opt.value);
                break;
            }
            default:
                break;
        }
    }

    return showHideColumnsState;
}

function initialOptionsState(reportFilters) {
    const optionsState = {};

    for (const filter of reportFilters) {
        const { filterKind } = filter;
        switch (filterKind) {
            case 'option': {
                optionsState[filter.optionId] = filter.options[0].value;
                break;
            }
            case 'dropdown':
            case 'multiselect':
            case 'show-hide-columns':
            case 'geography-type':
            case 'multi-suggest':
            case 'text-suggest':
                break;
            default:
                console.warn('Unhandled filter kind', filterKind);
                break;
        }
    }

    return optionsState;
}


const cachedConfigurations = {};

const SET_PRESET_FILTERS = 'SET_PRESET_FILTERS';
const SET_PRESET_OPTIONS = 'SET_PRESET_OPTIONS';
const SET_LOADING_CONFIGURATION = 'SET_LOADING_CONFIGURATION';
const SET_CONFIGURATION = 'SET_CONFIGURATION';
const SET_ERROR_LOADING_CONFIGURATION = 'SET_ERROR_LOADING_CONFIGURATION';
const SET_FILTERS = 'SET_FILTERS';
const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
const SET_SHOW_HIDE_COLUMNS = 'SET_SHOW_HIDE_COLUMNS';
const SET_QUERYING = 'SET_QUERYING';
const SET_ERROR_QUERYING = 'SET_ERROR_QUERYING';
const SET_RESULTS = 'SET_RESULTS';
const APPEND_RESULTS = 'APPEND_RESULTS';
const SET_FORMULARY_MODAL_DATA = 'SET_FORMULARY_MODAL_DATA';
const SET_RESTRICTIONS_MODAL_DATA = 'SET_RESTRICTIONS_MODAL_DATA';
const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
const HCP_MESSAGES_POD_COLUMN = {
    appId: null,
    id: "hcp_messages_PodPortal",
    kind: "link",
    label: "POD PORTAL",
    property: "PodPortal"
};
const HCP_MESSAGES_POD2_COLUMN = {
    appId: null,
    id: "hcp_messages_PodPortal",
    kind: "link",
    label: "POD PORTAL",
    property: "Uri"
};

function initialStateFromConfiguration(configuration) {
    const { filters, columns: configurationColumns, rowId, reportId, geoLevelOptions } = configuration;

    let columns = configurationColumns;
    if (reportId === reports.hcpMessagesPod2.id || reportId === reports.groupPracticeMessagesPod2.id ) {
        columns = [
            HCP_MESSAGES_POD2_COLUMN,
            ...configurationColumns
        ];
    }else if ((reportId === reports.hcpMessages.id || reportId === reports.groupPracticeMessages.id) && externalPODlinkMessage) {
        columns = [
            HCP_MESSAGES_POD_COLUMN,
            ...configurationColumns
        ];
    }

    const productAlignedFilters = alignConfigurationFilters(filters, columns);

    return {
        configuration: {
            rowId,
            filters: productAlignedFilters,
            columns,
            geoLevelOptions
        },
        filters: {
            ...initialFilterState(productAlignedFilters),
        },
        showHideColumns: {
            ...initialShowHideColumnsState(productAlignedFilters)
        },
        options: {
            ...initialOptionsState(productAlignedFilters),
        }
    };
}

function reducer(state, action) {
    switch (action.type) {
        case SET_PRESET_FILTERS: {
            if (equal(state.presetFilters, action.payload))
                return state;
            if (state.configuration) {
                return {
                    ...state,
                    presetFilters: action.payload,
                    filters: {
                        ...state.filters,
                        ...action.payload
                    }
                }
            }
            else {
                return {
                    ...state,
                    presetFilters: action.payload
                };
            }
        }
        case SET_PRESET_OPTIONS: {
            if (equal(state.presetOptions, action.payload))
                return state;
            if (state.configuration) {
                return {
                    ...state,
                    presetOptions: action.payload,
                    options: {
                        ...state.options,
                        ...action.payload
                    }
                }
            }
            else {
                return {
                    ...state,
                    presetOptions: action.payload
                };
            }
        }
        case SET_LOADING_CONFIGURATION: {
            return {
                ...initialReportState,
                presetFilters: state.presetFilters,
                loading: true
            };
        }
        case SET_CONFIGURATION: {

            const initialState = initialStateFromConfiguration(action.payload);
            return {
                ...state,
                configuration: initialState.configuration,
                loading: false,
                activeQuery: null,
                filters: {
                    ...initialState.filters,
                    ...state.presetFilters
                },
                showHideColumns: {
                    ...initialState.showHideColumns
                },
                options: {
                    ...initialState.options,
                    ...state.presetOptions
                },
                query: null,
                data: null
            };
        }
        case SET_ERROR_LOADING_CONFIGURATION: {
            return {
                ...initialReportState,
                loading: false,
                error: action.payload
            };
        }

        case SET_FORMULARY_MODAL_DATA: {
            return {
                ...state,
                formularyModalData: action.payload
            }
        }

        case SET_RESTRICTIONS_MODAL_DATA: {
            return {
                ...state,
                restrictionsModalData: action.payload
            }
        }

        case SET_FILTERS: {
            const newFilters = {
                ...state.filters,
                ...action.payload
            };

            const productAlignedFilterState = alignFilterStateToProduct(newFilters, state.configuration.filters);

            if (equal(state.filters, productAlignedFilterState))
                return state;

            const oldProductId = filterStateProductId(state.filters);
            const newProductId = filterStateProductId(productAlignedFilterState);

            // When we switch product id's, reset state of hidden columns
            // this is the best we can do because currently it's impossible to
            // properly map from one set of columns to another
            const showHideColumns = oldProductId === newProductId ? state.showHideColumns : initialShowHideColumnsState(state.configuration.filters);
            return {
                ...state,
                filters: productAlignedFilterState,
                showHideColumns: showHideColumns
            };
        }

        case SET_FILTER_OPTIONS: {
            const stateCopy = JSON.parse(JSON.stringify(state));
            const configurationFilters = stateCopy.configuration.filters;
            const prescriberNameFilter = configurationFilters.filter(obj => obj.id.toLowerCase() === 'prescribername')[0];
            prescriberNameFilter.options = action.payload;

            const newConfigurationFilters = configurationFilters.filter(obj => obj.id.toLowerCase() !== 'prescribername');
            newConfigurationFilters.push(prescriberNameFilter);
            stateCopy.configuration.filters = newConfigurationFilters;

            return stateCopy;
        }

        case SET_SHOW_HIDE_COLUMNS: {
            const newShowHideColumns = {
                ...state.showHideColumns,
                ...action.payload
            };

            if (equal(state.showHideColumns, newShowHideColumns))
                return state;

            return {
                ...state,
                showHideColumns: newShowHideColumns
            };
        }
        case SET_QUERYING: {
            return {
                ...state,
                loading: true,
                error: null,
                activeQuery: action.payload.query,
                query: action.payload.incremental ? state.query : null
            };
        }
        case SET_ERROR_QUERYING: {
            return {
                ...state,
                loading: false,
                activeQuery: null,
                error: action.payload
            };
        }
        case SET_RESULTS: {
            const { filters, order, results, continuationToken, totalRecords } = action.payload;

            // Not the same filters we queried with, ignore
            if (filters !== state.filters) {
                return state;
            }

            // Upcase object properties for case-insensitive column mapping
            const upcasedResults = results.map(uppercasePropertiesObject);

            return {
                ...state,
                loading: false,
                activeQuery: null,
                query: {
                    filters: filters,
                    order: order,
                    continuationToken: null
                },
                data: upcasedResults,
                continuationToken,
                totalRecords,
            };
        }
        case APPEND_RESULTS: {
            const { query, results, continuationToken, totalRecords } = action.payload;

            // Not the same query we started with, ignore
            if (query !== state.query)
                return state;

            // Upcase object properties for case-insensitive column mapping
            const upcasedResults = results.map(uppercasePropertiesObject);

            return {
                ...state,
                loading: false,
                activeQuery: null,
                query: {
                    ...query,
                    continuationToken: state.continuationToken
                },
                data: [...state.data, ...upcasedResults],
                continuationToken,
                totalRecords,
            }
        }
        case SET_SELECTED_COLUMNS: {
            return {
                ...state,
                selectedColumns: action.payload
            }
        }
        default:
            console.warn('Unknown action', action);
            return state;
    }
}

function uppercasePropertiesObject(object) {
    return Object.fromEntries(Object.entries(object).map(([name, value]) => [name.toUpperCase(), value]));
}

export function useReport({
    reportId,
    filters = {},
    options = {},
    queryOptions: {
        pageSize = 100
    } = {}
}) {
    const unmountedRef = useRef(false);
    const [reportState, dispatch] = useReducer(reducer, initialReportState, state => {
        const cachedConfiguration = cachedConfigurations[reportId];

        let cachedState = {};
        if (cachedConfiguration) {
            const initialState = initialStateFromConfiguration(cachedConfiguration);
            cachedState = {
                ...initialState,
                filters: {
                    ...initialState.filters,
                    ...filters
                },
                options: {
                    ...initialState.options,
                    ...options
                }
            };
        };

        return {
            ...state,
            ...cachedState,
            presetFilters: filters
        }
    });

    useEffect(() => {
        unmountedRef.current = false;
        return () => unmountedRef.current = true;
    }, []);

    useEffect(() => {
        dispatch({
            type: SET_PRESET_FILTERS,
            payload: filters
        });
    }, [filters]);

    useEffect(() => {
        dispatch({
            type: SET_PRESET_OPTIONS,
            payload: options
        });
    }, [options]);

    const loadConfiguration = useCallback(async () => {
        dispatch({
            type: SET_LOADING_CONFIGURATION
        });

        try {
            const configuration = await getReportConfiguration(reportId);
            configuration.reportId = reportId;
            cachedConfigurations[reportId] = configuration;

            if (unmountedRef.current)
                return;

            dispatch({
                type: SET_CONFIGURATION,
                payload: configuration
            });
        } catch (err) {
            if (unmountedRef.current)
                return;
            dispatch({
                type: SET_ERROR_LOADING_CONFIGURATION,
                payload: err
            });
        }
    }, [reportId]);

    const setFilterValues = useCallback(values => {
        dispatch({
            type: SET_FILTERS,
            payload: values
        });
    }, []);

    const setFilterOptions = useCallback(values => {
        dispatch({
            type: SET_FILTER_OPTIONS,
            payload: values,
        })
    }, []);

    const setShowHideColumns = useCallback(values => {
        dispatch({
            type: SET_SHOW_HIDE_COLUMNS,
            payload: values
        });
    }, [])

    const setOptionState = useCallback(values => {
        dispatch({
            type: SET_PRESET_OPTIONS,
            payload: values
        });
    }, []);

    const { formularyModalData } = reportState;

    const setFormularyModalData = useCallback(values => {
        dispatch({
            type: SET_FORMULARY_MODAL_DATA,
            payload: values
        });
    }, []);

    const { restrictionsModalData } = reportState;

    const setRestrictionsModalData = useCallback(values => {
        dispatch({
            type: SET_RESTRICTIONS_MODAL_DATA,
            payload: values
        });
    }, []);

    const { selectedColumns } = reportState;

    const setSelectedColumns = useCallback(values => {
        dispatch({
            type: SET_SELECTED_COLUMNS,
            payload: values
        });
    }, []);


    const queryData = useCallback(async filters => {
        const order = null;

        const queryOptions =
        {
            filters,
            order,
            continuationToken: null,
            pageSize
        };

        dispatch({
            type: SET_QUERYING,
            payload: {
                query: queryOptions,
                incremental: false
            }
        });

        try {
            const { results, continuationToken, totalRecords } = await queryReport(reportId, queryOptions);
            if (unmountedRef.current)
                return;

            dispatch({
                type: SET_RESULTS,
                payload: {
                    filters,
                    order,
                    results,
                    continuationToken,
                    totalRecords,
                }
            });
        } catch (err) {
            if (unmountedRef.current)
                return;
            dispatch({
                type: SET_ERROR_QUERYING,
                payload: err
            });
        }
    }, [dispatch, reportId, pageSize]);

    const { query } = reportState;

    const orderBy = useCallback(async order => {
        const { filters } = query;

        const queryOptions =
        {
            filters,
            order,
            continuationToken: null,
            pageSize
        };

        dispatch({
            type: SET_QUERYING,
            payload: {
                query: queryOptions,
                incremental: true
            }
        });

        try {
            const { results, continuationToken, totalRecords } = await queryReport(reportId, queryOptions);
            if (unmountedRef.current)
                return;
            dispatch({
                type: SET_RESULTS,
                payload: {
                    filters,
                    order,
                    results,
                    continuationToken,
                    totalRecords,
                }
            });
        } catch (err) {
            if (unmountedRef.current)
                return;
            dispatch({
                type: SET_ERROR_QUERYING,
                payload: err
            });
        }

    }, [reportId, query, pageSize])

    const { continuationToken } = reportState;
    const loadMore = useCallback(async () => {
        const { filters, order } = query;

        const queryOptions =
        {
            filters: filters,
            order: order,
            continuationToken: continuationToken,
            pageSize
        };

        dispatch({
            type: SET_QUERYING,
            payload: {
                query: queryOptions,
                incremental: true
            }
        });

        try {
            const { results, continuationToken, totalRecords } = await queryReport(reportId, queryOptions);
            if (unmountedRef.current)
                return;
            dispatch({
                type: APPEND_RESULTS,
                payload: {
                    query,
                    results: results,
                    continuationToken,
                    totalRecords,
                }
            });
        } catch (err) {
            if (unmountedRef.current)
                return;
            dispatch({
                type: SET_ERROR_QUERYING,
                payload: err
            });
        }
    }, [reportId, query, continuationToken, pageSize]);

    const { error, configuration, loading, activeQuery, data, totalRecords } = reportState;

    return {
        reportId,
        error,
        configuration,
        totalRecords,
        loading: loading,
        activeQuery,
        filters: reportState.filters,
        showHideColumns: reportState.showHideColumns,
        options: reportState.options,
        query: query && {
            filters: query.filters,
            order: query.order
        },
        data,
        formularyModalData,
        restrictionsModalData,
        loadConfiguration,
        setFilterValues: configuration && setFilterValues,
        setFilterOptions: configuration && setFilterOptions,
        setShowHideColumns: configuration && setShowHideColumns,
        setOptionState: configuration && setOptionState,
        queryData: configuration && queryData,
        orderBy: query && orderBy,
        loadMore: continuationToken && loadMore,
        setFormularyModalData,
        setRestrictionsModalData,
        selectedColumns,
        setSelectedColumns
    };
}

export function useSubreport({
    reportId,
    parentReport,
    filters = {},
    fields = [],
    queryOptions
}) {
    const allFilters = useMemo(() => ({
        ...(parentReport.filters || {}),
        ...filters
    }), [parentReport.filters, filters]);
    const report = useReport({
        reportId,
        filters: allFilters,
        queryOptions
    });
    /** Filter out columns manually using filters provided by api */
    if (report.configuration && reportId !== reports.payerLandscapeFormularies.id && reportId !== reports.payerLandscapeGroupPracticeFormularies.id && fields.length > 0) {
        report.configuration.columns = report.configuration.columns.filter(col => fields.includes(col.id));
    }
    return report;
}

const defaultComponents = {
    info: (props) => <button>{props.appId}</button>
};

function canSortColumn(column) {
    switch (column.kind) {
        case 'link': return false;
        case 'info': return false;
        default: return true;
    }
}

function makeColumnRenderer(column, theme, components) {
    const { appId, kind, property } = column;
    const upcasedProperty = property?.toUpperCase();

    switch (kind) {
        case 'string': return row => row[upcasedProperty] || '';
        case 'number': return row => numberString(row[upcasedProperty]);
        case 'percent': return row => percentString(row[upcasedProperty]);
        case 'html': return row => (
            <span dangerouslySetInnerHTML={{ __html: row[upcasedProperty] }} />
        );
        case 'info': {
            return row => {
                if (upcasedProperty && !row[upcasedProperty])
                    return null;

                return components.info({ appId, row });
            };
        }
        case 'link': {
            return row => {
                let url = new URL(row[upcasedProperty] || externalPODlinkMessage);

                return (
                    <ImageLink target="_blank" href={url?.href}>
                        <img src={theme.icons.printOnDemandPortal} alt='Icon' />
                    </ImageLink>
                );
            };
        }
        default:
            console.error(`Don't know how to render ${kind}`);
            return row => (<div>{row[upcasedProperty]}</div>);
    }
}


function makeColumnMetaRenderer(column, theme, setFormularyModalData, setRestrictionsModalData) {
    if (!column.info) return (() => <div></div>)
    const upcasedProperty = column.info.property.toUpperCase();
    switch (column.info.kind) {
        case 'reportpopup': return row => {
            const upperCasedRow = uppercasePropertiesObject(row);
            if (upperCasedRow[upcasedProperty]) {
                return <InfoIcon theme={theme} column={column} setModalData={setFormularyModalData} row={row}></InfoIcon>;
            }
            return null;
        }
        case 'htmlpopup': return row => {
            const upperCasedRow = uppercasePropertiesObject(row);
            if (upperCasedRow[upcasedProperty]) {
                return <InfoIcon theme={theme} column={column} setModalData={setRestrictionsModalData} row={row}></InfoIcon>;
            }
            return null;
        }

        default:
            return (() => <div></div>)
    }
}


function convertColumns(columns, theme, components, makeColumnMetaRenderer, setFormularyModalData, setRestrictionsModalData) {
    return columns.map((column, i) => {
        const { id, label, property, kind } = column;
        const renderer = makeColumnRenderer(column, theme, components);
        const rendererMeta = makeColumnMetaRenderer(column, theme, setFormularyModalData, setRestrictionsModalData);
        return {
            id: id || property || i,
            Header: label,
            Cell: ({ row }) => renderer(row.original),
            CellMeta: ({ row }) => rendererMeta(row.original),
            canOrder: canSortColumn(column),
            kind: kind,
        };
    });
}

function InfoIcon({ theme, setModalData, row, column }) {
    const onClick = () => setModalData({ ...column.info, row: row });
    return (
        <InfoLink>
            <div onClick={onClick} style={{ cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="icon_info" transform="translate(-809 -9)">
                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="8" cy="8" r="8" transform="translate(809 9)" fill={theme.colors.infoIconColor} />
                        <text id="i" transform="translate(815 21)" fill="#fff" fontSize="12" fontFamily="Georgia-BoldItalic, Georgia" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">i</tspan></text>
                    </g>
                </svg>
            </div>
        </InfoLink>
    );
}

function interpolateReportHeader(report, varName, toReplace) {
    return report.replace(varName, toReplace);
}

function configureReportHeader(formularyInfo) {
    let reportTemplate = formularyInfo.stringTemplateId;
    const planID = '{{PlanId}}';
    const planName = '{{PlanName}}';
    const benType = '{{Bentype}}';
    const payerDisplayName = '{{PayerDisplayName}}';
    const row = formularyInfo.row;
    reportTemplate = interpolateReportHeader(reportTemplate, planID, row.PLANID.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, planName, row.PAYERDISPLAYNAME.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, benType, row.BENTYPE.toString());
    reportTemplate = interpolateReportHeader(reportTemplate, payerDisplayName, row.PAYERDISPLAYNAME.toString());
    return reportTemplate;
}

export function Report({
    report,
    additionalFilters,
    onRowClick,
    children,
    mainGeography,
    setMainGeography,
    geoLevelOptions,
    displayFilter = true,
    disableQuery = false,
    components,
    isModal = false,
    selectedMessageCode = null,
    skipNationalAsDefaultGeography
}) {
    const theme = useTheme();
    const { startOp } = useContext(AppContext);
    const { reportId, configuration, loading, activeQuery, error, loadConfiguration, data, filters, showHideColumns, setShowHideColumns, query, queryData, orderBy, loadMore, setFilterValues, setFilterOptions, options, setOptionState, setFormularyModalData, formularyModalData, setRestrictionsModalData, restrictionsModalData, setSelectedColumns, selectedColumns } = report;

    const totalRecords = report.totalRecords || 0;
    const downloadLimit = 500000;

    const queriedFilters = query?.filters;
    const queriedOrder = query?.order;

    const [selectedCompetitors, setSelectedCompetitors] = useState([]);
    const [downloadable, setDownloadable] = useState(false);
    const [tooltip, setTooltip] = useState('');

    const containerRef = useRef(null);

    const configColumns = configuration && configuration.columns;

    const renderComponents = useMemo(() => ({ ...defaultComponents, ...components }), [components]);
    const location = useLocation();

    const pathName = location.pathname;

    const columnHeaderRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    
    const handleTooltipClick = useCallback( (event) => {
        const newSelectedColumns = {...selectedColumns};
        let selectedColumnChanged = false;
        columnHeaderRefs.forEach((ref) => {
            const columnId = ref.current?.attributes['data-column-id'].value;
            if(!ref.current || !columnId){
                return;
            }
            
            //if clicked outside the column header, set the selected state to false
            if (!ref.current.contains(event.target) ) {
                newSelectedColumns[columnId] = false;
            }else{
                //if clicked in the header, toggle the selected state
                newSelectedColumns[columnId] =  !selectedColumns[columnId];
            }
            //only update state/refresh when the status changed, this refresh was causing a bug with the HCP filter (missed clicks)
            if(newSelectedColumns[columnId] !== selectedColumns[columnId]){
                selectedColumnChanged = true;
            }
        });
        if(selectedColumnChanged){
            setSelectedColumns( newSelectedColumns);
        }
    }, [selectedColumns, setSelectedColumns]);

     useEffect(() => {
        document.addEventListener("click", handleTooltipClick, true);
        return () => {
            document.removeEventListener("click", handleTooltipClick, true);
        };
    }, [handleTooltipClick]); 

    const columns = useMemo(() => {
        if (!configColumns || !showHideColumns)
            return [];

        const hideColumns = [];
        for (const cols of Object.values(showHideColumns)) {
            if (!cols) continue;
            hideColumns.push(...cols);
        }

        const hasProductsInfo = (filteredColumns) => filteredColumns.some(col => col.hasOwnProperty("appliesToProducts"));

        let filteredColumns = configColumns.filter(({ id }) => !hideColumns.includes(id));

        if (filteredColumns && filteredColumns.length && hasProductsInfo(filteredColumns)) {

            filteredColumns = filteredColumns.filter(col => {
                if (!col.appliesToProducts?.length) return true;
                return col.appliesToProducts?.some(item => item === filters.ProductID);
            });

            filteredColumns = filteredColumns.filter(col => {
                return col.showInReport === undefined ? true : col.showInReport;
            })
        }

        return convertColumns(filteredColumns, theme, renderComponents, makeColumnMetaRenderer, setFormularyModalData, setRestrictionsModalData);
    }, [configColumns, showHideColumns, theme, renderComponents, setFormularyModalData, setRestrictionsModalData, filters]);

    const reportData = useMemo(() => data || [],
        [data]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns: columns,
        data: reportData,
        initialState: {
            hiddenColumns: ['payer_landscape_Identity']
        }
    });

    useEffect(() => {
        if (totalRecords && totalRecords < downloadLimit) {
            setDownloadable(true)
            setTooltip('DOWNLOAD SPREADSHEET')
        }

        if (totalRecords > downloadLimit) {
            setDownloadable(false)
            setTooltip('TOO MANY RESULTS TO DOWNLOAD.<br>USE MORE FILTERS')
        }

        if (!totalRecords) {
            setDownloadable(false)
            setTooltip('NO RESULTS TO DOWNLOAD')
        }

    }, [totalRecords])

    useEffect(() => {
        const container = containerRef.current;
        if (!container)
            return;
        if (!loadMore)
            return;

        const scrollListener = () => {
            //($0.offsetHeight + $0.scrollTop) === $0.scrollHeight
            if ((container.offsetHeight + container.scrollTop) === container.scrollHeight) {
                loadMore();
            }
        };

        container.addEventListener('scroll', scrollListener);

        return () => container.removeEventListener('scroll', scrollListener);

    }, [loadMore]);

    const isWinHighlight = useCallback((report, row) => {
        return theme.isWinEnabled && (report.reportId === reports.hcpTop5Plans.id || report.reporId === reports.groupPracticeTop5Plans.id || report.reportId === reports.hcpTop5PlansPod2.id || report.reportId === reports.groupPracticeTop5PlansPod2.id) && (row.original.WINLOSSFLAG?.toLowerCase() === "win" || row.original.WINFLAG === true);
    }, [theme.isWinEnabled])

    const closeModal = useCallback(() => {
        setFormularyModalData(null);
        setRestrictionsModalData(null);
    }, [setFormularyModalData, setRestrictionsModalData]);

    // Load report configuration
    useEffect(() => {
        if (!configuration)
            loadConfiguration();
    }, [loadConfiguration, configuration]);

    useEffect(() => {
        if (disableQuery
            || !configuration
            // We already queried for this combination
            || equal(filters, queriedFilters)
            // We are in the process of querying for this combination
            || equal(filters, activeQuery?.filters))
            return;
        queryData(filters);
    }, [disableQuery, configuration, queryData, filters, queriedFilters, activeQuery]);

    useEffect(() => {
        if(!configColumns){
            return;
        }
        const initialSelectedColumns = {};
        configColumns?.forEach((column) => {
            initialSelectedColumns[column.id] = false;
        });
        setSelectedColumns(initialSelectedColumns);
    }, [configColumns, setSelectedColumns]);

    const onExportClick = useCallback(() => {
        let request;
        const competitor = { ShowHideColumns: selectedCompetitors };
        request = { ...filters, ...options, ...competitor };       

        startOp(() => getExport(reportId, request));
    }, [pathName, reportId, filters, options, selectedCompetitors, startOp]);


    const onHeaderClick = useCallback((id, type) => {
        if (isSelectionActive())
            return;



        let newOrder;
        const isNumeric = type === "number" || type === "percent";

        // if column is not numeric, start with ascending
        if (!isNumeric) {
            if (!queriedOrder || queriedOrder.property !== id) {
                newOrder = {
                    property: id,
                    descending: false
                };
            }
            else if (!queriedOrder.descending) {
                newOrder = {
                    property: id,
                    descending: true
                };
            }
            else {
                newOrder = null;
            }
        }

        // if column is numeric, start with descending
        if (isNumeric) {
            if (!queriedOrder || queriedOrder.property !== id) {
                newOrder = {
                    property: id,
                    descending: true
                };
            }
            else if (queriedOrder.descending) {
                newOrder = {
                    property: id,
                    descending: false
                };
            }
            else {
                newOrder = null;
            }
        }


        orderBy(newOrder);
    }, [queriedOrder, orderBy]);    

    if (!configuration) {
        if (error) {
            return (
                <div>
                    Error loading report: {error.toString()}
                </div>
            );
        }

        if (loading)
            return (<span>Loading Report...</span>);

        return (<span>No report configuration</span>);
    }
    // Prepare the rows for rendering
    rows.forEach(prepareRow);

    function geoOverrideCheck() {
        return configuration.geoLevelOptions || geoLevelOptions;
    }

    return (
        <>
            {formularyModalData &&
                <Modal open={true} onClose={closeModal} >
                    <FormularyDrilldown parentReport={report} formularyInfo={formularyModalData}></FormularyDrilldown>
                </Modal>}
            {restrictionsModalData &&
                <Modal open={true} onClose={closeModal}>
                    <TopHcpsHeader>
                        <h1>{configureReportHeader(restrictionsModalData)}</h1>
                    </TopHcpsHeader>
                    <span dangerouslySetInnerHTML={{ __html: restrictionsModalData.row[restrictionsModalData.property.toUpperCase()] }}></span>
                </Modal>
            }

            <CustomReport>
                {displayFilter && configuration.filters.length > 0 &&
                    <ReportFilterGroup
                        filters={configuration.filters}
                        filterState={filters}
                        setFilterState={setFilterValues}
                        setFilterOptions={setFilterOptions}
                        optionState={options}
                        setOptionState={setOptionState}
                        mainGeography={mainGeography}
                        setMainGeography={setMainGeography}
                        geoLevelOptions={geoOverrideCheck()}
                        showColumns={showHideColumns}
                        setShowColumns={setShowHideColumns}
                        columns={configuration.columns}
                        setSelectedCompetitors={setSelectedCompetitors}
                        skipNationalAsDefaultGeography={skipNationalAsDefaultGeography}
                    >
                        {additionalFilters}
                    </ReportFilterGroup>}

                {children}

                <TableContainer isModal={isModal}>

                    <ScrollContainer>
                        <CustomTable $loading={loading} {...getTableProps()}>
                            <thead>
                                {headerGroups.map(hg => (
                                    <tr {...hg.getHeaderGroupProps()}>
                                        {hg.headers.map((h, i) => {
                                            const canOrder = h.canOrder;
                                            const sortDescending = queriedOrder?.property !== h.id ? null : queriedOrder.descending;
                                            const onClick = (loading || !canOrder) ? undefined : _ => onHeaderClick(h.id, h.kind);
                                            const currentColumnToolTip = configColumns?.find(c => c.id === h.id)?.tooltip;
                                            const currentColumnIsSelected = selectedColumns ? selectedColumns[h.id] : false;
                                            return (
                                                <Fragment key={i}>
                                                    <TableHeader onClick={onClick}>
                                                        {/* Sorting Bar Icon */}
                                                        {canOrder && (
                                                            <BarsContainer $sortDescending={sortDescending} $type={h.kind}>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </BarsContainer>
                                                        )}
                                                    </TableHeader>
                                                    {currentColumnToolTip && 
                                                                    <TableHeader ref={columnHeaderRefs[i]}  {...h.getHeaderProps()} data-column-id={h.id} hasTooltip="true" selected={currentColumnIsSelected}  data-tip={currentColumnToolTip} data-for={h.id} data-event="click">
                                                                        {h.render('Header')}
                                                                        {currentColumnIsSelected && <ReactTooltip id={h.id} globalEventOff='click'  place='bottom' backgroundColor='white' textColor='black' borderColor='gray' border={true} arrowColor='white' className='columnTooltip' multiline={true} html={true} padding='12px 16px'>
                                                                        </ReactTooltip> }
                                                                    </TableHeader>                                                                    
                                                    }
                                                    {!currentColumnToolTip && 
                                                                <TableHeader  {...h.getHeaderProps()}>
                                                                    {h.render('Header')}
                                                                </TableHeader>
                                                    }
                                                </Fragment>
                                            );
                                        })}

                                        <th>
                                            <TotalRecords pathName={pathName}>
                                                <p>{numberString(totalRecords)} {totalRecords === 1 ? 'result' : 'results'}</p>
                                            </TotalRecords>
                                            <ExcelIconImg
                                                onClick={downloadable ? onExportClick : null}
                                                alt=""
                                                data-tip={tooltip}
                                                data-for="excelIcon"
                                                src={theme.icons.excel}
                                                $downloadable={downloadable}
                                            />

                                            <ReactTooltip id="excelIcon" arrowColor="transparent" backgroundColor={theme.colors.secondary} multiline={true} />
                                        </th>
                                    </tr>
                                ))}

                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => (
                                    <TableRow
                                        onClick={onRowClick && (() => onRowClick(row.original))}
                                        $selected={row.original.MESSAGECODE === selectedMessageCode ? true : false}
                                        $isWin={isWinHighlight(report, row) ? true : false}
                                        data-tip={isWinHighlight(report, row) ? theme.winToolTipText : null}
                                        data-for={isWinHighlight(report, row) ? i.toString() : null} // id for ReactToolTip below
                                        {...row.getRowProps()}>
                                        {row.cells.map((cell, i) => (
                                            <Fragment key={i}>
                                                <td >
                                                    {cell.render('CellMeta')}
                                                </td>
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            </Fragment>
                                        ))}
                                        <td></td>

                                        {isWinHighlight(report, row)
                                            ? <td><ReactTooltip id={i.toString()} arrowColor="transparent" backgroundColor={theme.colors.secondary} multiline={true} /></td>
                                            : null
                                        }
                                    </TableRow>

                                ))}
                            </tbody>
                        </CustomTable>
                    </ScrollContainer>

                    {loading && (!data || data.length === 0) && <div>Loading Results...</div>}
                    {!loading && data && data.length === 0 && <div>No Results</div>}

                    {error && <div>Error loading report data: {error.toString()}</div>}
                    {loadMore &&
                        <LoadMoreButton disabled={loading} onClick={loadMore} />}
                </TableContainer>
            </CustomReport>

        </>
    );
}
