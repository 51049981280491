import PayerLandscapeReportContainer from '../components/PayerLandscapeReportContainer';
import { reports } from '../reports';
import { useTheme } from "styled-components";

function PayerLandscapeGroupPractice() {
	const theme = useTheme();
	return (
		<PayerLandscapeReportContainer
			payerLandscapeReportId={reports.payerLandscapeGroupPractice.id}
			topSubReportId={reports.payerLandscapeTopGroupPractices.id}
			topSubReportTitle={theme.titles.payerLandscapeTopGroupPractices}></PayerLandscapeReportContainer>
	);
}

export default PayerLandscapeGroupPractice;
