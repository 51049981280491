import getDefaultGeoOption from "../util/getDefaultGeoOption";
export const SET_STATE = 'SET_STATE';
export const SET_MAIN_GEOGRAPHY = 'SET_MAIN_GEOGRAPHY';


export function init(configuration) {
    const { geoLevelOptions, skipNationalAsDefaultGeography } = configuration;
    const geoLevelOption = geoLevelOptions[0];
    const kind = geoLevelOption.value;
    const geographyOption = getDefaultGeoOption(geoLevelOption, skipNationalAsDefaultGeography);

    const { value, label } = geographyOption || { id: null, label: null };

    const mainGeography = {
        kind,
        id: value,
        name: label
    };

    return ({
        geographySelection: {
            history: [],
            mainGeography
        }
    });
}

export function reducer(state, action) {
    switch (action.type) {
        case SET_STATE:
            return ({
                ...state,
                ...action.state
            });
        case SET_MAIN_GEOGRAPHY:
            return reducer(state, {
                type: SET_STATE,
                state: {
                    geographySelection: {
                        ...state.geographySelection,
                        history: [],
                        mainGeography: action.value
                    }
                }
            });
        default:
            console.error('unknown action:', action);
            return state;

    }
}
