import React, { useState, useEffect, useMemo } from 'react';
import DropdownFilter from './DropdownFilter';
import { getDynamicOptions } from '../api/report';
import { deepCompareEquals } from '../util/useDeepCompareWithRef';

function DynamicFilter({
	id,
	colId,
	label,
	filterKind,
	components,
	closeMenuOnSelect,
	hideSelectedOptions,
	isDisabled = false,
	isHcpName,
	filterState,
	setFilterState,
	setFilterOptions,
	isMulti,
	filterWithoutPayerDisplayName,
}) {
	const [dynamicOptions, setDynamicOptions] = useState([]);
	const [lastFilteredOptions, setLastFilteredOptions] = useState();
	const setValue = (v) => {
		setFilterState({
			[id]: v,
		});
	};

	const value = filterState[id];
	const selectedOptions = useMemo(() => {
		return (value && value.map((v) => dynamicOptions.find((opt) => opt.value === v)).filter((x) => x !== undefined)) || [];
	}, [dynamicOptions, value]);

	// If our selected options don't line up with the current value, set the new selected value
	// this happens if the new list list of available options changes to not include items from our previous selection

	// this useEffect fixes bug AND bad state call error:
	useEffect(() => {
		if (value && value.length && selectedOptions.length !== value.length) {
			setFilterState((prevState) => {
				return {
					...prevState,
					[id]: selectedOptions.map((opt) => opt.value),
				};
			});
		}
	}, [value, selectedOptions, id, setFilterState]);

	useEffect(() => {
		let filteredOptions = filterWithoutPayerDisplayName;
		let ignore = false;

		//remove the current field id from the filterWithoutPayerDisplayName so the dynamic options can be obtained without filtering by the current field (which would break multi select)
		if (Object.keys(filterWithoutPayerDisplayName).some((f) => f === id)) {
			filteredOptions = Object.assign({}, filterWithoutPayerDisplayName);
			delete filteredOptions[id];
		}
		// If the filtered options have changed, get the new dynamic options
		if (!lastFilteredOptions || !deepCompareEquals(lastFilteredOptions, filteredOptions)) {
			if (!ignore) {
				getDynamicOptions(filteredOptions, colId).then((options) => {
					if(!ignore){
						setDynamicOptions(options);
						setLastFilteredOptions(filteredOptions);
					}
				});
			}
		}
		return () => {
			ignore = true;
		};
	}, [filterState, filterWithoutPayerDisplayName, colId, id, lastFilteredOptions]);

	return (
		<DropdownFilter
			label={label}
			filterKind={filterKind}
			options={dynamicOptions}
			onChange={(options) => setValue(options.map((opt) => opt.value))}
			setOptions={(v) => setFilterOptions(v)}
			value={selectedOptions}
			components={components}
			closeMenuOnSelect={closeMenuOnSelect}
			hideSelectedOptions={hideSelectedOptions}
			isDisabled={isDisabled}
			isHcpName={isHcpName}
			filterState={filterState}
			isMulti={isMulti}
		/>
	);
}

export default DynamicFilter;
