import { apiGet, apiPut, apiDelete, apiPost } from "./AGIAPI";

export async function getUsers() {
    return await apiGet('user');
}

export async function createUser(user) {
    return await apiPost('user', user);
}

export async function createOrUpdateUser(user) {
    return await apiPut(`user/${encodeURIComponent(user.email)}`, user);
}

export async function deleteUser(user) {
    return await apiDelete(`user/${encodeURIComponent(user.email)}`);
}

export async function getUser(email) {
    return await apiGet(`user/${encodeURIComponent(email)}`);
}

export async function track(event) {
    return await apiPost('user/track', event);
}
