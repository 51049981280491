import equal from 'fast-deep-equal';
import getDefaultGeoOption from '../util/getDefaultGeoOption';
export const SET_STATE = 'SET_STATE';
export const PUSH_HISTORY = 'PUSH_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const SET_MAIN_GEOGRAPHY = 'SET_MAIN_GEOGRAPHY';

export function init (configuration) {
    const {geoLevelOptions, skipNationalAsDefaultGeography} = configuration;
    const geoLevelOption = geoLevelOptions[0];
    const kind = geoLevelOption.value;
    const geographyOption = getDefaultGeoOption(geoLevelOption, skipNationalAsDefaultGeography);

    const { value, label } = geographyOption || { id: null, label: null};

    const mainGeography = {
        kind,
        id: value,
        name: label
    };

    return ({
        geographySelection: {
            history: [],
            mainGeography
        }
    });
}

export function reducer(state, action) {
    switch (action.type) {
        case SET_STATE:
            return ({
                ...state,
                ...action.state
            });
        case PUSH_HISTORY:
            const { geographySelection } = state;
            const { history, mainGeography } = geographySelection;
            const setHistory = value => {
                if (equal(history, value)) {
                    return state;
                }
                return {
                    ...state,
                    geographySelection: {
                        ...state.geographySelection,
                        history: value
                    }
                };
            };

            const newItem = action.item;
            if (newItem === null)
                return setHistory([]);
            if (newItem.id === mainGeography.id)
                return setHistory([]);

            const idx = history.findIndex(item => item.kind === newItem.kind);
            if (idx === -1)
                return setHistory([...history, newItem]);

            return setHistory([...history.slice(0, idx), newItem]);
        case CLEAR_HISTORY:
            return reducer(state, {
                type: SET_STATE,
                state: {
                    geographySelection: {
                        ...state.geographySelection,
                        history: []
                    }
                }
            });
        case SET_MAIN_GEOGRAPHY:
            return reducer(state, {
                type: SET_STATE,
                state: {
                    geographySelection: {
                        ...state.geographySelection,
                        history: [],
                        mainGeography: action.value
                    }
                }
            });
        default:
            console.error('unknown action:', action);
            return state;

    }
}
