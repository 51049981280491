import React from 'react';
import styled from 'styled-components';

const ColorCube = styled.div`
    width: 15px;
    height: 15px;
    background-color: $mediumBlue;
    margin: .3rem;
`;

const LegendLabelDiv = styled.div`
    font-weight: bold;
`;

const LegendHeaderContainer = styled.div`
    display: flex;
    width: 100%;
`;

const LegendSubDiv = styled.div`
    font-weight: 400;
    font-size: small;
    color: gray;
    padding: 0 0 0 25px;

    @media screen and (max-width: 820px) {
        color: #111;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 180px;

    @media screen and (max-width: 820px) {
        background-color: #eee;
        padding: 5px;
    }
`;

const LegendContainer = styled.div`
    @media screen and (max-width: 820px) {
        opacity: 0.75;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;

        ${({ isHorizontal }) => !isHorizontal && `
            flex-direction: column;
        `}
    }
`;

function Legend({
    legendItems,
    customLegendStyle,
    isHorizontal = false
}) {
    // Map through array of object
    const displayLegend = legendItems.map((legendItem, index) => (
        <FlexDiv key={index}>
            <LegendHeaderContainer>
                <ColorCube style={{ backgroundColor: legendItem.color }} />
                <LegendLabelDiv>{legendItem.label}</LegendLabelDiv>
            </LegendHeaderContainer>
            <LegendSubDiv>{legendItem.subLabel}</LegendSubDiv>
        </FlexDiv>
    ));

    return (
        <LegendContainer isHorizontal={isHorizontal} style={customLegendStyle || null}>{displayLegend}</LegendContainer>
    );
}

export default Legend;
